import React, { useEffect, useState } from 'react';
import '../../../assets/sass/mob/selectionPanel.sass';
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import { CaretDownOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Input } from 'antd';

export declare type PostionType = 'top' | 'right' | 'bottom' | 'left';
interface IProps {
    items: any[];
    keyName: string;
    labelName: string;
    multiSelect?: boolean;
    selectedItems?: any[];
    defaultIds?: any[];
    onSelectedItems?: (selectedItem) => void;
    onSelectedIds?: (selectedItem) => void;
    position?: PostionType;
    size?: number;
    style?: object;
    actionName?: string;
    isSearch?: boolean;
    title?: string;
}

const SelectionPanel = (props: IProps) => {
    const [onOpen, setOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [displayCount, setDisplayCount] = useState(0);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [keyName, setKeyName] = useState("");
    const [title, setTitle] = useState("");
    const [labelName, setLabelName] = useState("");
    const [display, setDisplay] = useState("");
    const [multiSelect, setMultiSelect] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        if (props.defaultIds) {
            setSelectedIds(props.defaultIds);
            if (props.defaultIds.length > 1) {
                setDisplayCount(props.defaultIds.length - 1);
            }
            let defaultItems = [];
            let count = 0;
            for (let item in props.items) {
                if (props.defaultIds.indexOf(props.items[item][keyName]) > -1) {
                    defaultItems.push(props.items[item]);
                    if (count === 0) {
                        setDisplay(props.items[item][labelName]);
                    }
                    count++
                }
            }
            setSelectedItems(defaultItems);
        }
        //eslint-disable-next-line
    }, [props.defaultIds, props.items]);

    useEffect(() => {
        if (props.title) {
            setTitle(props.title || "")
        }
    }, [props.title])

    useEffect(() => {
        if (props.size) {
            setSize(props.size);
        }
    }, [props.size]);

    useEffect(() => {
        if (props.items) {
            setItems(props.items);
        }
    }, [props.items]);

    useEffect(() => {
        if (props.keyName) {
            setKeyName(props.keyName);
        }
    }, [props.keyName]);

    useEffect(() => {
        if (props.labelName) {
            setLabelName(props.labelName);
        }
    }, [props.labelName]);

    useEffect(() => {
        if (props.multiSelect) {
            setMultiSelect(props.multiSelect);
        }
    }, [props.multiSelect]);


    useEffect(() => {
        if (props.isSearch) {
            setIsSearch(props.isSearch);
        }
    }, [props.isSearch]);

    useEffect(() => {
        if (props.selectedItems) {
            setSelectedItems(props.selectedItems);
        }
    }, [props.selectedItems]);

    useEffect(() => {
        if (items) {
            if (keyName && labelName && typeof selectedItems === "object" && selectedItems.length > 0) {
                let count = 0;
                setDisplay(selectedItems[0][labelName]);
                if (selectedItems.length > 1) {
                    count = selectedIds.length - 1;
                }
                setDisplayCount(count);
            } else {
                setDisplay("")
            }
        } else {
            setDisplay("");
        }
        if (!multiSelect) {
            setOpen(false);
        }
        if (props.onSelectedItems) {
            props.onSelectedItems(selectedItems);
        }
        if (props.onSelectedIds) {
            props.onSelectedIds(selectedIds);
        }
        //eslint-disable-next-line
    }, [selectedItems]);

    useEffect(() => {
        setMultiSelect(props.multiSelect || false);
    }, [props.multiSelect]);

    const onSelectItem = (selectItem) => {
        if (multiSelect) {
            let exitIndex = null;
            if (keyName) {
                exitIndex = selectedIds.indexOf(selectItem[keyName]) > -1;
                if (!exitIndex) {
                    setSelectedIds(preState => [...preState, selectItem[keyName]]);
                    setSelectedItems(preState => [...preState, selectItem]);
                } else {
                    setSelectedIds(selectedIds.filter(sItem => sItem !== selectItem[keyName]));
                    setSelectedItems(selectedItems.filter(sItem => sItem[keyName] !== selectItem[keyName]));
                }
            }
        } else {
            setSelectedIds([selectItem[keyName]]);
            setSelectedItems([selectItem]);
        }
    }

    return <div className="selection-panel">
        <div className="selected-item" onClick={() => setOpen(true)}>
            <label>{display}</label>
            {multiSelect && displayCount > 0 && <div className="display-count">+{displayCount}</div>}
            <CaretDownOutlined style={{ marginLeft: 6, fontSize: 22, color: '#000' }} />
        </div>
        <SlidingPanel
            type={props.position || 'bottom'}
            isOpen={onOpen}
            size={size || 100}
            panelClassName="panelClassName">
            <div className="content" style={props.style}>
                <div className="header">
                    {title && <div className="title">{title}</div>}
                    {isSearch && <Input className="search" type="text" placeholder={"Search ..."} suffix={<CloseCircleFilled style={{
                        fontSize: 18,
                        color: '#adb7c3'
                    }} />} />}
                </div>
                {/* {(title && title.length > 0) || isSearch &&} */}
                {/* (multiSelect ? isSearch ? 134 : 90 : isSearch ? 84 : 34) */}
                <div style={{ height: window.innerHeight - ((title && title.length > 0 && isSearch) ? 164 : (title && title.length > 0) || isSearch ? 134 : 90) }} className="items">
                    {items.map((item, i) => {
                        return <div className="item" key={`item_${i}`} onClick={() => onSelectItem(item)}>
                            <label>{(keyName && item[labelName]) || item}</label>
                            {selectedIds && selectedIds.length > 0 && selectedIds.indexOf(item[keyName]) > -1 && <CheckCircleFilled key={`check_${i}`} style={{ color: "#33E1BE", fontSize: 18 }} />}
                            {/* {selectedItems.length > 0 && item[keyName] && selectedItems.map((selectItem, j) => { return selectItem[keyName] === item[keyName] && <CheckCircleFilled key={`check_${j}`} style={{ color: "#33E1BE", fontSize: 18 }} /> })} */}
                        </div>
                    })}
                </div>
                {multiSelect && <div className="action">
                    <button onClick={() => setOpen(false)}>
                        {props.actionName || "done"}
                    </button>
                </div>}
                {!multiSelect && <div className="action">
                    <button onClick={() => setOpen(false)}>
                        {props.actionName || "Close"}
                    </button>
                </div>}
            </div>
        </SlidingPanel>
    </div>
}

export default SelectionPanel;