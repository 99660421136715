import React, { useState, useEffect } from "react";
import Tour from "reactour";
import { useNav } from "../helpers/customHook";

const HeaderTour = () => {
    const navigateTo = useNav();
    const [isTourOpen, setTourOpen] = useState(false);
    useEffect(() => {
        setTourOpen(true);
    }, []);
    const steps = [{
        selector: '[data-tut="reactour__select-store"]',
        position: 'bottom',
        content: ({ goTo, close, }) => {
            return <div className="container">
                <div className="title">
                    <div>Customise your shop website (i.e) theme, content management, layout, etc…</div>
                </div>
                <div className="action">
                    <div
                        className="skip"
                        onClick={() => close()}
                    >
                        Skip
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <div
                            className="pre"
                            onClick={() => goTo(1)}
                        >
                            Prev
                        </div>
                        <div
                            className="next"
                            onClick={() => navigateTo("/home")}
                        >
                            Next
                        </div>
                    </div>
                </div>
            </div>
        }
    }];
    return <Tour
        showNavigation={false}
        showNavigationNumber={false}
        showCloseButton={false}
        showButtons={false}
        showNumber={false}
        // maskClassName="custom-mask"
        // maskSpace={30}
        onRequestClose={() => { setTourOpen(false) }}
        steps={steps}
        isOpen={isTourOpen}
        className="onboarding"
    />
}

export default HeaderTour;