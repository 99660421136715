import React, { useEffect, useState } from "react";
// import { useNav } from "../helpers/customHook";
import Tour from "reactour";
import TextTemplate from "./TextTemplate";
// import MediaTemplate from "./MediaTemplate";

// <div className="onboarding-container">
//                 <div className="point">
//                     <div className="inner-cricle"></div>
//                 </div>
//                 <div className="title">
//                     <img src={TitleSvg} alt="title" />
//                     <div>Change the outlets</div>
//                 </div>
//                 <div className="content">Copy about a feature or functionality the tip is near. Be specific and suggest  actions if possible. </div>
//                 <div className="action">
//                     <div className="skip" onClick={() => close()}>Skip</div>
//                     <div style={{
//                         display: 'flex',
//                         justifyContent: 'space-between'
//                     }}>
//                         <div
//                             className="pre"
//                             onClick={() => navigateTo("/selectstore")}
//                         >
//                             Prev
//                         </div>
//                         <div
//                             className="next"
//                             onClick={() => goTo(1)}
//                         >
//                             Next
//                         </div>
//                     </div>
//                 </div>
//             </div>

const HeaderTour = () => {
    // const navigateTo = useNav();
    const [isTourOpen, setTourOpen] = useState(false);
    useEffect(() => {
        setTourOpen(true);
    }, []);
    const steps = [{
        selector: '[data-tut="reactour__change_outlets"]',
        // position: 'bottom',
        content: ({ goTo, close, }) => {
            return <TextTemplate
                onClose={close}
                nextAction={() => goTo(1)}
                title={"Change the outlets"}
                description={"Copy about a feature or functionality the tip is near. Be specific and suggest  actions if possible."}
            />
        }
    }, {
        selector: '[data-tut="reactour__logout"]',
        position: 'right',
        content: ({ goTo, close, }) => {
            return <TextTemplate
                onClose={close}
                nextAction={() => goTo(1)}
                nextLabel="Completed"
                prevLabel="Prev"
                prevAction={() => goTo(1)}
                title={"User signout"}
                description={"Copy about a feature or functionality the tip is near. Be specific and suggest  actions if possible."}
            />
        }
    }];
    return <Tour
        showNavigation={false}
        showNavigationNumber={false}
        showCloseButton={false}
        showButtons={false}
        showNumber={false}
        disableFocusLock={true}
        highlightedMaskClassName='highlighted_mask'
        disableKeyboardNavigation={true}
        onRequestClose={() => { setTourOpen(false) }}
        steps={steps}
        isOpen={isTourOpen}
        className="onboarding"
        maskClassName='onboarding-mask'
    />
}

export default HeaderTour;