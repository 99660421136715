import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getIndustryTypes, sendOTP, verifyOTP, getDomainAvailable, createNewStore, getStoreAvailable } from "../../../actions/setupStoreActions";
import { setSetupStoreDetails, selectedStore } from "../../../actions/uiAction";
import { clearDomainAvailable, clearDomainUnavailable, signout } from "../../../actions/commonAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/dsk/setupstore.sass';
import { Input, Select } from 'antd';
// import { LikeFilled, DislikeFilled } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
// import InputValidation from '../common/InputValidation';
import { isNotNullOrUndefinedString } from '../../../helpers/util';
// import Loading from '../common/Loading';
// import PopupConfirm from '../common/PopupConfirm';
// import Header from './Header';
// import Footer from './Footer';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useNav } from '../../../helpers/customHook';

dayjs.extend(timezone)
const { Option } = Select;
const SetUpStore = (props: any) => {
    const [setIsLoading]: any = useOutletContext();
    const navigateTo = useNav();
    const { industryTypes: industryTypeList } = useSelector((state: any) => state.setupStore);
    const { setupStoreDetails } = useSelector((state: any) => state.ui);
    const [industryTypes, setIndustryTypes] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    // const [tc, setTC] = useState(false);
    const [storeName, setStoreName] = useState(null);
    const [industryType, setIndustryType] = useState(null);
    const [brandName, setBrandName] = useState(null);
    const [isStoreAvailable, setIsStoreAvailable] = useState(null);
    const [error, setError] = useState({
        industryType: {
            message: "",
            type: ""
        },
        brandName: {
            message: "",
            type: ""
        },
        storeName: {
            message: "",
            type: ""
        },
        tc: {
            message: "",
            type: ""
        }
    });
    // const windowHeight = window.innerHeight;

    useEffect(() => {
        props.selectedStore(null);
        props.getIndustryTypes();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (setupStoreDetails) {
            if (setupStoreDetails.brandName) {
                setBrandName(setupStoreDetails.brandName);
            }
            if (setupStoreDetails.industryType) {
                setIndustryType(setupStoreDetails.industryType);
            }
            if (setupStoreDetails.storeName) {
                setStoreName(setupStoreDetails.storeName);
            }
        }
    }, [setupStoreDetails])

    useEffect(() => {
        if (industryTypeList) {
            setIndustryTypes(industryTypeList);
        }
    }, [industryTypeList]);

    const onSubmitHandler = async () => {
        let storeAvailableStatus = isStoreAvailable;
        setIsLoading(true);
        let errorInfo = {
            industryType: {
                message: "",
                type: ""
            },
            brandName: {
                message: "",
                type: ""
            },
            storeName: {
                message: "",
                type: ""
            },
            tc: {
                message: "",
                type: ""
            }
        },
            isError = false;
        setError(errorInfo);
        if (!isNotNullOrUndefinedString(storeName)) {
            errorInfo.storeName.type = "error";
            errorInfo.storeName.message = "Fill the domain name";
            isError = true;
        } else if (storeAvailableStatus === null) {
            storeAvailableStatus = await getStoreAvailable(storeName);
        }
        if (!isNotNullOrUndefinedString(brandName)) {
            errorInfo.brandName.message = "Fill the brand name";
            errorInfo.brandName.type = "error";
            isError = true;
        }

        if (!isNotNullOrUndefinedString(industryType)) {
            errorInfo.industryType.message = "Select the industry type";
            errorInfo.industryType.type = "error";
            isError = true;
        }
        if (!isError && storeAvailableStatus) {
            const payload = {
                industryType,
                storeName,
                brandName,
                tz: dayjs.tz.guess()
            };
            props.setSetupStoreDetails(payload);
            // const resp = await props.createNewStore(payload);
            // if (resp) {
            // setIsLoading(false);
            navigateTo(`/${storeName.toLowerCase()}/chooseplan`);
            // }
        } else {
            setIsLoading(false);
            setError(errorInfo);
        }
    }

    const getStoreAvailable = async (sName) => {
        let status = false;
        const resp = await props.getStoreAvailable(sName);
        let errorInfo = {
            storeName: {
                message: "",
                type: ""
            }
        };
        setError({ ...error, ...errorInfo });
        if (resp.error) {
            setIsStoreAvailable(false);
        } else if (resp) {
            setIsStoreAvailable(resp.isAvailable);
            status = true;
        }
        return status;
    }

    const displayDomainName = () => {
        const hostUrl = window.location.hostname.indexOf('localhost') > -1 ? 'app.testing.shopletzy.com' : window.location.hostname;
        const domainName = hostUrl.replace('app', storeName || '').replace('shopletzy', 'myshopletzy');
        return domainName;
    }

    // const { t } = props
    return (
        <div>
            <div className="title">
                Create Store
            </div>
            <div className="subtitle">
                Tell us a little about your business
            </div>
            <div className="business">
                <div className="group">
                    <div className="label">Brand Name *</div>
                    <Input type="text" placeholder="Enter brand name" value={brandName}
                        onChange={event => setBrandName(event.target.value)}
                    />
                    <div className="error" style={{ marginTop: 2, marginLeft: 4 }}>{error.brandName.message}</div>
                </div>

                <div className="group">
                    <div className="label">Domain Name *</div>
                    <Input type="text" placeholder="Enter domain name" value={storeName}
                        onChange={event => { setIsStoreAvailable(null); setStoreName(event.target.value.trim()) }}
                        onBlur={event => event.target.value && getStoreAvailable(event.target.value)}
                    />
                    <div className="error" style={{ marginTop: 2, marginLeft: 4 }}>{error.storeName.message}</div>
                    <div style={{ display: 'flex', marginTop: 8 }}>
                        <div style={{ color: isStoreAvailable === true ? '#0076FF' : isStoreAvailable === false ? '#BD1616' : "#000", marginRight: 4 }}>{displayDomainName()} </div>
                        {isStoreAvailable === true && <div> is available</div>}
                        {isStoreAvailable === false && <div >is not available</div>}
                    </div>

                </div>

                <div className="group">
                    <div className="label">Industry Type *</div>
                    <Select placeholder="Select Industry Type" value={industryType} onChange={(value) => { setIndustryType(value) }}>
                        {industryTypes.map((i) => {
                            return <Option key={i.value} value={i.value}>{i.label.toUpperCase()}</Option>
                        })}
                    </Select>
                    <div className="error" style={{ marginTop: 2, marginLeft: 4 }}>{error.industryType.message}</div>
                </div>
                {/* <div className="group">
                            <div className="tc" style={{ marginTop: "30px" }}>
                                <Checkbox style={{ marginRight: 10 }} defaultChecked={tc} onChange={(e) => { setTC(e.target.checked) }} />
                                <span className="agree">I agree</span><span className="sure"> Terms & Conditions</span> <span className="agree">and</span> <span className="sure"> Privacy Policy</span>
                                <div className="error">{error.tc.message}</div>
                            </div>
                        </div> */}
                <button style={{ marginTop: 16 }} className="sp_btn next-btn" onClick={onSubmitHandler}>Create Store</button>
            </div>
            {/* {isLoading && <Loading background="#00000070"></Loading>} */}
        </div>
    );
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        setupStore: state.setupStore
    }
}

export default connect(mapStateToProps, {
    signout,
    getIndustryTypes,
    createNewStore,
    sendOTP,
    verifyOTP,
    setSetupStoreDetails,
    selectedStore,
    getStoreAvailable,
    getDomainAvailable,
    clearDomainAvailable,
    clearDomainUnavailable
})(withTranslation()(SetUpStore));
