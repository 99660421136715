import React from "react";
import TitleSvg from "../assets/images/onboard-title.svg";

interface IProps {
    onClose: () => void;
    nextAction?: () => void;
    prevAction?: () => void;
    prevLabel?: string;
    nextLabel?: string;
    title: string;
    description: string;
}

const TextTemplate = (props: IProps) => {
    const { onClose, nextAction, prevAction, title, description, nextLabel = "Next", prevLabel = "Prev" } = props;
    return <div className="onboarding-text-template">
        <div className="point">
            <div className="inner-cricle"></div>
        </div>
        <div className="title">
            <img src={TitleSvg} alt="title" />
            <div>{title}</div>
        </div>
        <div className="content">{description}</div>
        <div className="action">
            <div className="skip" onClick={onClose}>Skip</div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                {prevAction && typeof prevAction === "function" && <div
                    className="pre"
                    onClick={prevAction}
                >
                    {prevLabel}
                </div>}
                {nextAction && typeof nextAction === "function" && <div
                    className="next"
                    onClick={nextAction}
                >
                    {nextLabel}
                </div>}
            </div>
        </div>
    </div>
}

export default TextTemplate;