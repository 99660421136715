import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getStores } from "../../../actions/setupStoreActions";
import { selectedStore } from "../../../actions/uiAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/mob/setupstore.sass';
import { withTranslation } from 'react-i18next';
// import { CONSTANTS } from '../../../contants';
import NoStoreSvg from "../../../assets/images/no_stores.svg";
import { useNav } from '../../../helpers/customHook';

const Staff = (props: any) => {
    const navigateTo = useNav();
    const [stores, setStores] = useState(null);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const onSelectedStore = async (store) => {
        await props.selectedStore(store);
        navigateTo('/home');
    }
    useEffect(() => {
        getStoreDetails();
        //eslint-disable-next-line
    }, []);
    const getStoreDetails = async () => {
        const resp = await props.getStores();
        setStores(resp);
    }
    const onCreateStore = () => {
        navigateTo('/setupstore');
    }
    // const { t } = props
    return <div className="setupstore_mob" style={{ height: dimensions.height }}>
        <div className="header">
            <div className="logo">
                <label>Your Stores</label>
            </div>
        </div>
        <div className="content" style={{ padding: "32px 30px" }}>
            {(!stores || stores.length === 0) && <>
                <img alt='Store Icon' src={NoStoreSvg} style={{ height: 187 }} />
                <div className="subtitle" style={{ fontSize: 18, color: "#000", marginTop: 58, padding: "0px 20px", textAlign: "center" }}>
                    You are not part of any store yet. Tell your store admin to grant you access
                </div>
                <div className="subtitle" style={{ fontSize: 18, marginTop: 87, textAlign: "center" }}>
                    If you are the store owner
                </div>
            </>}
            <button onClick={onCreateStore} style={{ marginTop: 24 }}>
                Create New Store
            </button>
            {(stores && stores.length > 0) && <>
                <div style={{ fontSize: 18, color: '#000000', textAlign: 'left' }}>Stores</div>
                {stores.map((store) => {
                    return <div key={store} onClick={() => onSelectedStore(store)} className="store_card">
                        <div className="logo">
                            {store.name.charAt(0).toUpperCase()}
                        </div>
                        <div className="detail">
                            <div className="name">{store.name}</div>
                            <div className={`${store.userType.toLowerCase()}_type`}>{store.userType}</div>
                        </div>
                    </div>
                })}
            </>}
        </div>
    </div>
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    getStores,
    selectedStore
})(withTranslation()(Staff));
