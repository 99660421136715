import React, { useEffect, useState } from 'react'
import "../../../assets/sass/dsk/helpCenter.sass";
import HelpCenterSvg from "../../../assets/images/help-center.svg";
import DownloadSvg from "../../../assets/images/download.svg";
import PopoverUrlSvg from "../../../assets/images/popover-url.svg";
import Loading from '../common/Loading';
import PurchaseProductCatalog from './PurchaseProductCatalog';
import { Drawer } from 'antd';
import ArrowLeftSvg from '../../../assets/images/arrow-left.svg';
import { downloadInvoice, getInvoiceByServiceId, getSubscriptions } from '../../../actions/billingAction';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { purchaseService } from '../../../actions/setupStoreActions';
import { useNav } from '../../../helpers/customHook';

const HelpCenter = () => {
    const dispatch = useDispatch();
    const navigateTo = useNav();
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [storeSubscription, setStoreSubscription] = useState(null);
    const [numberProducts, setNumberProducts] = useState(50);
    const [numberProductError, setNumberProductError] = useState(null);
    const [isRecentPurchased, setRecentPurchased] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState(null);
    const [invoiceData, setInvoiceData] = useState(null);
    const [isVisibleViewPurchases, setIsVisibleViewPurchases] = useState(false);
    const loadSubscription = async () => {
        const resp = await getSubscriptions()(dispatch);
        if (resp?.storeSubscription) {
            setStoreSubscription(resp.storeSubscription);
        }
    }
    useEffect(() => {
        loadSubscription();
        //eslint-disable-next-line
    }, []);
    const Help = [{
        title: "Community",
        desc: "Join our active community to connect with fellow store owners, share tips, and get real-time support. Collaborate, learn, and grow together!",
        onClick: () => {
            window.open("https://www.reddit.com/r/Shopletzy", "_blank");
        }
    }, {
        title: "Theme Development",
        desc: "Explore guides and resources for designing and customizing themes. Get step-by-step instructions to create and enhance your store's unique look.",
        onClick: () => {
            window.open("https://www.shopletzy.com/docs/getting-started", "_blank");
        }
    }, {
        title: "Tutorials",
        desc: "Learn how to set up and manage your store with our easy, step-by-step video guides. Perfect for quick tips and in-depth walkthroughs!",
        onClick: () => {
            window.open("https://www.youtube.com/@Shopletzy", "_blank");
        }
    }];
    const Services = [{
        title: "Product Catalog Setup",
        desc: "Get your store ready fast! We’ll help with product catalog uploads and store configuration, ensuring everything is set up smoothly so you can start selling right away.",
        action: {
            type: "button",
            label: "Purchase",
            name: "PurchaseProductCatalog"
        }
    }];
    const QuickLinks = [{
        title: "View Purchases",
        onClick: async () => {
            setIsLoading(true);
            const resp = await getInvoiceByServiceId(null, 'product_catalog_setup')(dispatch);
            if (resp?.invoices) {
                setInvoiceData(resp.invoices);
                setIsVisibleViewPurchases(true);
            }
            setIsLoading(false);
        }
    }, {
        title: "Billing",
        onClick: () => {
            navigateTo(`/${selectedStore.name}/billing/plans`);
        }
    }];
    const onProceed = async () => {
        setIsLoading(true);
        const resp = await purchaseService({
            "serviceId": "product_catalog_setup",
            "purchaseData": {
                "noOfProducts": numberProducts
            }
        }, storeSubscription?.id)(dispatch);
        setIsLoading(false);
        if (resp && resp?.error) {
            setNumberProductError('Something went wrong. Please try again later.');
        } else {
            setRecentPurchased(true);
            setAction(null);
        }
    }
    const onAction = (action) => {
        setAction(action);
    }
    const onCloseViewPurchases = () => {
        setNumberProducts(50);
        setIsVisibleViewPurchases(false);
        setAction(null);
    }
    const onDownloadInvoice = async (invoice) => {
        setIsLoading(true);
        const resp = await downloadInvoice(null, invoice.id)(dispatch);
        if (resp && resp?.url) {
            const link = document.createElement('a');
            link.href = resp?.url;
            link.target = '_blank';
            link.download = `${invoice.id}_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
        setIsLoading(false);
    }
    return <div className="help-center">
        {isRecentPurchased && <div className='recent-purchased'>
            Your last purchased at {dayjs(new Date().getTime()).format('DD MMM, YYYY')}
        </div>}
        <div className='header' style={{ marginTop: isRecentPurchased ? 32 : 0 }}>
            <div className='container'>
                <div style={{ width: 646 }}>
                    <div className='title'>
                        Welcome to the Shopletzy Help Center!
                    </div>
                    <div className='desc'>
                        Find all the resources you need, from documentation and community support to video tutorials. Explore service offerings like catalog setup and dedicated support. Check out our FAQs for quick answers and get the help you need to succeed!
                    </div>
                    <div className='link'>support@shopletzy.com</div>
                </div>
                <img src={HelpCenterSvg} style={{ marginRight: 42 }} alt='help center' />
            </div>
        </div>
        <div className='help'>
            <div className='title'>How can we help?</div>
            <div className='cards'>
                {Help.map((item, index) => {
                    return <div className='item' key={`h-${index}`}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
                            <div className='title'>{item.title}</div>
                            <div className='action'>
                                <img src={PopoverUrlSvg} alt='Popover Link' style={{ cursor: "pointer" }} onClick={item.onClick} />
                            </div>
                        </div>
                        <div className='desc'>{item.desc}</div>

                    </div>
                })}
            </div>
        </div>
        <div className='help services'>
            <div className='title'>Services</div>
            <div className='cards'>
                {Services.map((item, index) => {
                    return <div className='item' key={`s-${index}`}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
                            <div className='title'>{item.title}</div>
                        </div>
                        <div className='desc'>{item.desc}</div>
                        <div className='action' style={{ marginTop: 20 }}>
                            <button className='sp_btn' style={{ width: 'auto', cursor: "pointer" }} onClick={() => onAction(item.action)}>{item.action.label}</button>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div className='help quick-links'>
            <div className='title'>Quick links</div>
            <div className='links'>
                {QuickLinks.map((item: any, index) => {
                    return <div className='link' key={`ql-${index}`} >
                        <div>{item.title}</div>
                        <img src={PopoverUrlSvg} alt='Popover Link' style={{ cursor: "pointer" }} onClick={item?.onClick} />
                    </div>
                })}
            </div>
        </div>
        <Drawer
            maskClosable={false}
            width={409}
            closable={false}
            onClose={onCloseViewPurchases}
            className="drawer-view view-purchases"
            open={isVisibleViewPurchases}
        >
            <div className="header" style={{ justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <img src={ArrowLeftSvg} className="arrow" onClick={onCloseViewPurchases} alt='Arrow Left' />
                    <div className="title">View Purchases</div>
                </div>
            </div>
            <div style={{ height: window.innerHeight - 70, overflow: 'auto', padding: 12 }}>
                {invoiceData && invoiceData.map((item, index) => {
                    return <div className='card' key={`i-${index}`}>
                        <div style={{ flexGrow: 1 }}>
                            <div className='vp-title'>Product Catalog Setup</div>
                            <div className='vp-invoice-no'>#{item.invoiceNo}</div>
                            <div className='vp-date'>{dayjs(item.createdAt).format('DD MMM, YYYY')}</div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end'
                        }}>
                            <img style={{ cursor: 'pointer' }} src={DownloadSvg} alt='Download' onClick={() => onDownloadInvoice(item)} />
                        </div>
                    </div>
                })}
            </div>
        </Drawer>
        {action?.name === "PurchaseProductCatalog" && <PurchaseProductCatalog
            onCancel={onCloseViewPurchases}
            onProceed={onProceed}
            setNumberProducts={setNumberProducts}
            numberProducts={numberProducts}
            error={numberProductError} />}
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}

export default HelpCenter;