import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getIndustryTypes, sendOTP, verifyOTP, createMerchant } from "../../../actions/setupStoreActions";
import { setSetupStoreDetails } from "../../../actions/uiAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/mob/setupstore.sass';
import { withTranslation } from 'react-i18next';
import logo from "../../../assets/images/ShopLetzy_logo_wh_s1.svg";
import { useNav } from '../../../helpers/customHook';

const SendOTP = (props: any) => {
    const navigateTo = useNav();
    const [mobileNumber, setMobileNumber] = useState("");
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const onChangeMobileNo = (value) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setMobileNumber(value)
        }
    }
    const onSendOTP = async () => {
        if (mobileNumber.length === 10) {
            const code = "91";
            const resp = await props.sendOTP(code, mobileNumber);
            if (resp) {
                navigateTo(`/verifyOTP/${mobileNumber}`);
            }
        }
    }
    // const { t } = props
    return <div className="setupstore_mob" style={{ height: dimensions.height }}>
        <div className="header">
            <div className="logo">
                <img alt='Logo' src={logo} />
                <label>Shopletzy</label>
            </div>
        </div>
        <div className="content" style={{ height: dimensions.height }}>
            <div className="title">Lets get your mobile number verified</div>
            <div className="subtitle"><div style={{ float: 'left' }}>We will send you an</div> <div className="highlight">One Time Password</div> <div>on this mobile number</div></div>
            <div className="otp">
                <div className="label">Mobile Number</div>
                <div className="number">
                    <div className="country_code">+91</div>
                    <div className="divider"></div>
                    <input type="text" placeholder="Enter Mobile Number" value={mobileNumber} pattern="[0-9]*" maxLength={10} onChange={(e) => onChangeMobileNo(e.target.value)} />
                </div>
            </div>
        </div>
        <div className="footer">
            <button style={{ cursor: mobileNumber.length === 10 ? 'pointer' : 'default' }} onClick={onSendOTP}>SEND OTP</button>
        </div>
    </div>
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { getIndustryTypes, sendOTP, verifyOTP, setSetupStoreDetails, createMerchant })(withTranslation()(SendOTP));
