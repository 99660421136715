import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getIndustryTypes, sendOTP, verifyOTP, createMerchant } from "../../../actions/setupStoreActions";
import { setSetupStoreDetails } from "../../../actions/uiAction";
// import Locale from "../common/Locale";
import '../../../assets/sass/mob/setupstore.sass';
import { withTranslation } from 'react-i18next';
// import { CONSTANTS } from '../../../contants';
import logo from "../../../assets/images/ShopLetzy_logo_wh_s1.svg";
import { isMobile } from '../../../helpers/util';
import { useNav } from '../../../helpers/customHook';

let otpTimeCounter = null;
const VerifyOTP = (props: any) => {
    const navigateTo = useNav();
    const [mobileNumber, setMobileNumber] = useState("");
    const [otp, setOTP] = useState("");
    const [otpTimestamp, setOTPTimestamp] = useState(0);
    const [otpRunningTimer, setOTPRunningTimer] = useState(null);
    const [error, setError] = useState(null);
    const { otpTimestamp: otpTimestampProp } = useSelector((state: any) => state.setupStore);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        if (props.match.params.mobileNo) {
            setMobileNumber(props.match.params.mobileNo);
            setOTPTimestamp(new Date().getTime());
            otpTimer();
        }
        //eslint-disable-next-line
    }, [props.match.params.mobileNo]);

    useEffect(() => {
        setOTPTimestamp(otpTimestampProp);
    }, [otpTimestampProp])

    useEffect(() => {
        otpTimer();
        //eslint-disable-next-line
    }, [otpTimestamp]);

    const otpTimer = () => {
        // Set the date we're counting down to
        let countDownDate = otpTimestamp + (300 * 1000);
        if (otpTimeCounter) {
            clearInterval(otpTimeCounter);
        }
        // Update the count down every 1 second
        otpTimeCounter = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();
            // Find the distance between now and the count down date
            let distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            setOTPRunningTimer(minutes + ":" + (seconds.toString().length === 1 ? "0" + seconds.toString() : seconds));
            if (distance < 0) {
                clearInterval(otpTimeCounter);
                setOTPRunningTimer(null);
            }
            //eslint-disable-next-line
        }.bind(this), 1000);
    }

    const onVerifyOTP = async () => {
        if (otp.length === 6 && mobileNumber.length === 10) {
            const resp = await props.verifyOTP("91", mobileNumber, otp);
            if (resp.error) {
                setError(resp.error);
            } else {
                navigateTo("/userType");
            }
        }
    }

    const resendOTP = (e) => {
        e.preventDefault();
        let code = "91";
        if (otpRunningTimer === null && isMobile(mobileNumber)) {
            props.sendOTP(code, mobileNumber)
        }
    }

    // const { t } = props
    return <div className="setupstore_mob" style={{ height: dimensions.height }}>
        <div className="header">
            <div className="logo">
                <img alt='Logo' src={logo} />
                <label>Shopletzy</label>
            </div>
        </div>
        <div className="content" style={{ height: dimensions.height }}>
            <div className="title">OTP Verification</div>
            <div className="subtitle"><div style={{ float: 'left' }}>Enter the </div> <div className="highlight">OTP</div> <div style={{ float: 'left' }}>sent to +91</div><div className="highlight" style={{ fontWeight: 'normal' }}>{mobileNumber}</div></div>
            <div className="otp">
                <div className="label">OTP</div>
                <div className="number">
                    <input style={{ textAlign: "center" }} maxLength={6} type="text" placeholder="Enter Verification Code" onChange={(e) => setOTP(e.target.value)} />
                </div>
                <div className="error">{error}</div>
                <div onClick={sendOTP} className="resend">
                    <div className="timer">{otpRunningTimer}</div><div style={{ color: otpRunningTimer ? '#a7a7a7' : '#4A9CFD' }} className="action" onClick={resendOTP}>Resend OTP</div>
                </div>
            </div>
        </div>
        <div className="footer">
            <button style={{ cursor: mobileNumber.length === 10 ? 'pointer' : 'default' }} onClick={onVerifyOTP}>Verify OTP</button>
        </div>
    </div>
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { getIndustryTypes, sendOTP, verifyOTP, setSetupStoreDetails, createMerchant })(withTranslation()(VerifyOTP));
