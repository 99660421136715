import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
// import types from "./types/actionTypes";
import 'react-markdown-editor-lite/lib/index.css';
import SignIn from "./components/mob/auth/Signin";
// import PopupConfirm from "./components/mob/common/PopupConfirm";
import Products from "./components/mob/product";
import Home from "./components/mob/home";
import OnBoarding from "./onBoarding";
import Main from "./components/mob/common/Main";
import SendOTP from "./components/mob/store/SendOTP";
import VerifyOTP from "./components/mob/store/VerifyOTP";
import UserType from "./components/mob/store/UserType";
import Staff from "./components/mob/store/Staff";
import SetupStore from "./components/mob/store/SetupStore";
import SelectStore from "./components/mob/store";
import "./assets/sass/mob/common.sass";
import { useSelector } from "react-redux";

const App = (props: any) => {
  // class App extends Component<any, any> {

  // constructor(props: any) {
  // super(props)
  // let userJson = localStorage.getItem('userInfo');
  // const { selectedMainMenu } = useSelector((state: any) => state.ui);
  const { info, isLoggedIn } = useSelector((state: any) => state.auth);
  // let selectedMainMenu = store.getState().ui.selectedMainMenu;
  // let userInfo = (userJson !== "" && userJson !== null) ? JSON.parse(userJson) : null;
  // props.dispatch({
  //   type: type.AUTH_SUCCESS,
  //   data: userInfo && userInfo.info
  // });
  // const dispatch: any = useDispatch();
  const [appState, setAppState] = useState(null);
  // const [error, setError] = useState(null);
  // const [popupConfirm, setPopupConfirm] = useState(null);
  // const [theme, setTheme] = useState({
  //   primaryColor: "#000000",
  //   secondaryColor: "#e97a43",
  //   secondaryLighterColor: "#ffe5d9",
  //   buttonBGColor: "#242540",
  //   buttonFGColor: "#fff",
  // });
  useEffect(() => {
    if (info && isLoggedIn && info.isMobileVerified === true) {
      setAppState("LoggedIn")
    } else if (info && isLoggedIn) {
      if (info.isMobileVerified === false) {
        setAppState("SendOTP")
      } else {
        setAppState("CreateStore")
      }
      // if (['storeaddress', 'storemap', 'sendOTP', 'verifyOTP/:mobileNo'].map(o => '/' + o).includes(location.pathname)) {
      //   console.log("::::::::::::::::::::::::::::::::::::::::::::")
      // } else {
      //   setAppState("SendOTP")
      // }
    } else {
      setAppState(null)
    }
  }, [info, isLoggedIn])
  useEffect(() => {
    console.log(appState)
  }, [appState])
  // this.state = {
  //   selectedMainMenu,
  //   loggedIn: false,
  //   error: null,
  //   popupConfirm: {
  //     title: () => { return this.state.error && this.state.error.title },
  //     type: "error",
  //     renderContent: () => { return this.state.error && this.state.error.message },
  //     okay: {
  //       label: "Okay",
  //       callback: this.clearError
  //     }
  //   },
  //   theme: {
  //     primaryColor: "#000000",
  //     secondaryColor: "#e97a43",
  //     secondaryLighterColor: "#ffe5d9",
  //     buttonBGColor: "#242540",
  //     buttonFGColor: "#fff",
  //   }
  // };

  // props.dispatch({
  //   type: type.LOCALE,
  //   data: {
  //     key: "en",
  //     value: "English"
  //   }
  // });
  // props.i18n.changeLanguage("en");

  // const clearError = () => {
  //   dispatch({
  //     type: types.ERROR,
  //     data: null
  //   })
  // }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   let updateProps: any = {}
  //   if (nextProps.common) {
  //     updateProps.error = nextProps.common.error;
  //   }
  //   return updateProps;
  // }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   let updateProps = {}
  //   if (nextProps.common) {
  //     updateProps.error = nextProps.common.error;
  //   }
  //   if (nextProps && nextProps.auth && nextProps.auth.info && prevState && (!prevState.userInfo || (prevState.userInfo && prevState.userInfo.token != nextProps.auth.info.token))) {
  //     localStorage.setItem("userInfo", JSON.stringify(nextProps.auth));
  //     updateProps.userInfo = nextProps.auth;
  //   }
  //   if (nextProps && nextProps.auth && !nextProps.auth.info) {
  //     updateProps.userInfo = null;
  //   }
  //   return updateProps;
  // }

  // render() {
  // console.log(props)
  return <>
    {/* <ThemeSwitcher theme={this.state.theme}> */}
    <div className="shopletzy-app">
      <OnBoarding></OnBoarding>
      <Routes>
        <Route path="/signin" element={
          info ? <Route path={"/selectstore"} /> : <SignIn  {...props} />
        } />
        {(appState === "LoggedIn" || appState === "CreateStore") && <Route path="/setupstore" element={<SetupStore />} />}
        {appState === "SendOTP" && <Route path="/userType" element={<UserType />} />}
        {appState === "SendOTP" && <Route path="/sendOTP" element={<SendOTP />} />}
        {appState === "SendOTP" && <Route path="/verifyOTP/:mobileNo" element={<VerifyOTP />} />}
        {appState === "SendOTP" && <Route path="/staff" element={<Staff />} />}
        {appState === "LoggedIn" && <Route path="/selectstore" element={<SelectStore />} />}
        {appState === "LoggedIn" && <Route path="/:storeName" element={<Main />}>
          <Route path="home" element={<Home />} />
          <Route path="products" element={<Products />} />
          {/* <Route path="customers" element={<ListedCutomer />} />
          <Route path="customers/:id/orders" element={<CustomerOrders />} />
          <Route path="categories" element={<Categories />} >
            <Route index element={<ListedCategory {...props}></ListedCategory>} />
          </Route>
          <Route path="orders" element={<Orders />} >
            <Route index element={<CurrentOrders {...props}></CurrentOrders>} />
            <Route path="current" element={<CurrentOrders {...props}></CurrentOrders>} />
            <Route path="past" element={<PastOrders {...props}></PastOrders>} />
          </Route>
          <Route path="orders/details/:id" element={<OrderView />} />
          <Route path="settings/account/:options" element={<Account />} />
          <Route path="settings/store" element={<MyStore />} >
            <Route path={`siteconfiguration`} element={<SiteConfiguration />} >
              <Route path={`overview`} element={<Overview />} />
              <Route path={`sitestatus`} element={<SiteStatus />} />
              <Route path={`sociallinks`} element={<SocialLinks />} />
            </Route>
            <Route path="outlet" element={<ListedOutlets />} />
            <Route path="outlet/:id/detail" element={<OutletDetail />} />
            <Route path="user" element={<User />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="businessHours" element={<BusinessHours />} />
            <Route path="deliverySlots" element={<DeliverySlots />} />
            <Route path="*" element={<ComingSoon />} />
          </Route>
          <Route path="settings/channels" element={<Channels />} >
            <Route path="web" element={<Web />} >
              <Route path={`branding`} element={<BrandingWeb />} >
                <Route path={`general`} element={<GeneralWeb />} />
                <Route path={`home`} element={<BrandingHomeWeb />} />
                <Route path={`landing`} element={<LandingWeb />} />
              </Route>
              <Route path={`codeinjection`} element={<CodeInjectionWeb />} />
              <Route path={`customdomain`} element={<CustomDomain />} />
            </Route>
            <Route path="mobile" element={<Mobile />} >
              <Route path={`branding`} element={<BrandingWeb />} >
                <Route path={`general`} element={<GeneralMobile />} />
                <Route path={`home`} element={<BrandingHomeMobile />} />
                <Route path={`landing`} element={<LandingMobile />} />
              </Route>
              <Route path={`codeinjection`} element={<CodeInjectionMobile />} />
              <Route path={`appgeneration`} element={<AppGeneration />} >
                <Route path={`android`} element={<Android></Android>} />
                <Route path={`ios`} element={<ComingSoon style={{ height: '60vh' }} title={"iOS"} />} />
              </Route>
            </Route>
          </Route>
          <Route path="settings/support" element={<Support />} >
            <Route path="faq" element={<Faq />} />
          </Route>
          <Route path="settings/account" element={<Account />} >
            <Route path="bankdetails" element={<Bank />} />
            <Route path="plansbillings" element={<Billing />} />
          </Route>
          <Route path="settings" element={<Settings />} />
          <Route path="promotions" element={<Promotions />} >
            <Route index element={<PromotionListed />} />
          </Route> */}
        </Route>}
        <Route path="/" element={info ? <SelectStore /> : <SignIn  {...props} />} />
      </Routes>
    </div>
    {/* <Error error={this.state.error}></Error> */}
    {/* {error && <PopupConfirm config={popupConfirm}></PopupConfirm>} */}
    {/* </ThemeSwitcher> */}
  </  >
  // }
}

export default App;