import React from 'react';
import '../../../assets/sass/mob/actionPanel.sass';
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';

const ActionPanel = (props: any) => {
    return <SlidingPanel
        type={props.position || 'bottom'}
        isOpen={props.onOpen}
        size={props.size || 100}>
        <div className="action-panel" style={props.style}>
            {props.children}
        </div>
    </SlidingPanel>
}

export default ActionPanel;