import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { CloseCircleOutlined, FilterFilled } from "@ant-design/icons";
import { getProducts } from '../../../actions/productActions';
import { selectedMainMenu } from '../../../actions/uiAction';
import { getOutlets } from '../../../actions/outletAction';
import { getL10N, loadOutletBasedProduct } from "../../../helpers/middleware";
import SearchIcn from "../../../assets/images/search.svg";
import '../../../assets/sass/mob/products.sass';
import SelectionPanel from '../common/SelectionPanel';
import { Badge, Input } from 'antd';
import ActionPanel from '../common/ActionPanel';
import config from '../../../apiConfig';
import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";

const Inventory: any = [{ id: 'all', label: 'All' }, { id: 'instock', label: 'In Stock' }, { id: 'outofstock', label: 'Out Of Stock' }]
const ListedProducts = (props: any) => {

    const { selectedStore, navBarHidden } = useSelector((state: any) => state.ui);
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [outlets, setOutlets]: any = useState([]);
    const [selectedDefaultOutlet, setSelectedDefaultOutlet]: any = useState([]);
    const [productList, setProductList] = useState(null);
    const [products, setProducts] = useState([]);
    const [isFitlerOptionOpen, setFitlerOptionOpen] = useState(false);
    const [isFitlerEnable, setFitlerEnable] = useState(false);
    const [productSearch, setProductSearch] = useState("");
    const [categorySearch, setCategorySearch] = useState("");
    const [priceSearch, setPriceSearch] = useState("");
    const [quantitySearch, setQuantitySearch] = useState("");
    const [stockSearch, setStockSearch] = useState("all");
    const loadProducts = async () => {
        let resp = await props.getProducts();
        setProducts(resp);
        setProductList(resp);
    }

    const loadOutlets = async () => {
        let resp = await props.getOutlets();
        if (resp) {
            setOutlets(resp);
        }
    }

    useEffect(() => {
        loadOutlets();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedStore) {
            loadProducts();
        }
        // eslint-disable-next-line
    }, [selectedStore])

    // useEffect(() => {
    //     if (productList == null && selectedStore) {
    //         loadProducts();
    //     } else {
    //         onFilterProducts();
    //     }
    // }, [productList]);

    useEffect(() => {
        onFilterProducts()
        // eslint-disable-next-line
    }, [selectedOutlets]);

    // useEffect(() => {
    //     if (props.selectedOutlet && props.selectedOutlet != selectedOutlet) {
    //         setSelectedDefaultOutlet([props.selectedOutlet]);
    //     }
    // }, [props.selectedOutlet]);

    useEffect(() => {
        if (outlets && outlets.length > 0) {
            setSelectedDefaultOutlet([outlets[0].id]);
            setSelectedOutlets([outlets[0].id])
            onFilterProducts();
        }
        // eslint-disable-next-line
    }, [outlets]);

    useEffect(() => {
        if (priceSearch.length > 0 || quantitySearch.length > 0 || categorySearch.length > 0 || stockSearch !== 'all') {
            setFitlerEnable(true)
        } else {
            setFitlerEnable(false)
        }
        onFilterProducts();
        // eslint-disable-next-line
    }, [productSearch, priceSearch, quantitySearch, categorySearch, stockSearch, productList])

    const isStockStatus = (record) => {
        let status = true;
        if (!loadOutletBasedProduct(record, "isAvailable", selectedOutlets)) {
            status = false;
        }
        if (loadOutletBasedProduct(record, "outOfStock", selectedOutlets)) {
            status = false;
        }
        return status;
    }

    const onFilterProducts = () => {
        if (productList) {
            let filterProducts = [];
            let isCategory = false;
            let isProduct = false;
            let isPrice = false;
            let isQuantity = false;
            let isOutlet = false;
            let isStock = false;
            for (let p of productList) {
                isCategory = false;
                isProduct = false;
                isOutlet = false;
                isPrice = false;
                isQuantity = false;
                isStock = false;
                if (productSearch) {
                    let title = getL10N(p, "title", null).i10n;
                    if ((title && title.toLowerCase().indexOf(productSearch.toLowerCase()) > -1) || p.sku.includes(productSearch)) {
                        isProduct = true
                    }
                } else {
                    isProduct = true;
                }
                if (categorySearch) {
                    let categoryNames = p.categories.join('|').replace("/", "");
                    if (categoryNames.toLowerCase().indexOf(categorySearch.toLowerCase()) > -1) {
                        isCategory = true
                    }
                } else {
                    isCategory = true;
                }
                if (p.outlets && p.outlets.length > 0 && selectedOutlets) {
                    for (let i in p.outlets) {
                        if (selectedOutlets === p.outlets[i].ouId) {
                            isOutlet = true;
                            if (priceSearch && priceSearch.toString().length > 0 && p.outlets[i].price.toString().match(priceSearch.toString())) {
                                isPrice = true;
                            } else if (!priceSearch || priceSearch.toString().length === 0) {
                                isPrice = true;
                            }
                            if (quantitySearch && quantitySearch.toString().length > 0 && p.outlets[i].stock.toString().match(quantitySearch.toString())) {
                                isQuantity = true;
                            } else if (!quantitySearch || quantitySearch.toString().length === 0) {
                                isQuantity = true;
                            }
                            if ((stockSearch && stockSearch.toString().length > 0 && stockSearch !== "all" && p.outlets[i].outOfStock === false && stockSearch === "instock") || (p.outlets[i].outOfStock === true && stockSearch === "outofstock")) {
                                isStock = true;
                            } else if (!stockSearch || stockSearch.toString().length === 0 || stockSearch === "all") {
                                isStock = true;
                            }
                        }
                    }
                }
                if (isOutlet && isProduct && isPrice && isQuantity && isCategory && isStock) {
                    filterProducts.push(p);
                }
            }
            setProducts(filterProducts)
        }
    }

    const loadCategories = (record) => {
        // let categories = [];
        let searchRegExp = /\//g;
        let replaceWith = ' / ';
        let label: string = "";
        let counts = 0;
        if (record.categories && record.categories.length > 0) {
            label = record.categories[0].replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase());
            counts = record.categories.length - 1;
            // for (let i in record.categories) {

            // categories.push(<Badge key={record.categories[i]} count={record.categories[i].replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase())} />);
            // }
        }
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <Badge key={label} count={label} />
            {counts > 0 && <div style={{ marginLeft: 8, color: "#404040" }}>+{counts}</div>}
        </div>;
    }

    return <div className="listed-product">
        <div className="product-header">
            <SelectionPanel title={"Select Outlets"} defaultIds={selectedDefaultOutlet} onSelectedIds={(items) => setSelectedOutlets(items)} labelName={"name"} keyName={"id"} items={outlets}></SelectionPanel>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Input className='input-box' style={{ flexGrow: 1 }} onChange={(event) => { setProductSearch(event.target.value) }} type="text" placeholder={"Search ..."} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                <div className="filter-option" onClick={() => setFitlerOptionOpen(true)}>
                    <FilterFilled style={{ color: isFitlerEnable ? '#33E1BE' : '#fff' }} />
                </div>
            </div>
        </div>
        {/* style={{ height: window.innerHeight - 240 }} */}
        <div className="products-items" style={{ bottom: navBarHidden ? 0 : 84 }} >
            {products && products.map((record, index) => {
                return <div key={record.id} className="product-item">
                    <div className="icon">
                        {(!record.images || record.images.length === 0) && <img alt='Not Available' src={NoImageAvailableIcn} style={{
                            margin: "0px 10px",
                            width: "50px",
                            borderRadius: "3px"
                        }} />}
                        {record.images && record.images.length > 0 &&
                            <img alt='Stock Status' style={{
                                width: 100,
                                height: 90,
                                borderRadius: 4,
                                margin: "0px 10px",
                                objectFit: "cover"
                            }}
                                className={isStockStatus(record) ? "" : "grayscale"}
                                src={`${config.baseImageUrl}${record.images && record.images[0]}`} />
                        }
                    </div>
                    <div className="details">
                        <div>
                            <div className="title">{getL10N(record, "title", null).i10n}</div>
                            <div className="subtitle">{getL10N(record, "subtitle", null).i10n}</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {loadCategories(record)}
                                <div className="price">₹{loadOutletBasedProduct(record, "price", selectedOutlets, "amount")}</div>
                            </div>

                        </div>
                    </div>
                </div>
            })}
        </div>
        <ActionPanel onOpen={isFitlerOptionOpen} size={70}>
            <div className="menu">
                <div className="title">Filter Options</div>
                <div onClick={() => setFitlerOptionOpen(false)}>
                    <CloseCircleOutlined style={{ fontSize: 20 }} />
                </div>
            </div>
            <div className="filter-options">
                <div className="filter-item">
                    <Input placeholder="Category" className='input-box' name="categorySearch" value={categorySearch} onChange={(event) => setCategorySearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                </div>
                <div className="filter-item">
                    <Input placeholder="Price" className='input-box' type={"number"} value={priceSearch} name="priceSearch" onChange={(e) => setPriceSearch(e.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                </div>
                <div className="filter-item">
                    <Input placeholder="Quantity" className='input-box' value={quantitySearch} name="quantitySearch" onChange={(event) => setQuantitySearch(event.target.value)} suffix={<img alt='Search Icon' src={SearchIcn} />} />
                </div>
                <div className="filter-item">
                    <SelectionPanel defaultIds={["all"]} size={100} onSelectedIds={(items) => { items[0] && setStockSearch(items[0]) }} labelName={"label"} keyName={"id"} items={Inventory}></SelectionPanel>
                </div>
            </div>
            {/* <div className="filter-action">
                <button>Apply Fitler</button>
            </div> */}
        </ActionPanel>
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProducts,
        getOutlets,
        selectedMainMenu
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ListedProducts)
