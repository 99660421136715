import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getIndustryTypes, createNewStore, getStoreAvailable } from "../../../actions/setupStoreActions";
// import Locale from "../common/Locale";
import '../../../assets/sass/mob/signin.sass';
import { withTranslation } from 'react-i18next';
// import PopupConfirm from '../common/PopupConfirm';
import PopupConfirm from '../common/PopupConfirm';
// import NoStoreSvg from "../../../assets/images/no_stores.svg";
import { isNotNullOrUndefinedString } from '../../../helpers/util';
import { Select } from 'antd';
import { useNav } from '../../../helpers/customHook';

const { Option } = Select;

const SetupStore = (props: any) => {
    const navigateTo = useNav();
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const { industryTypes: industryTypeList } = useSelector((state: any) => state.setupStore);
    const [industryTypes, setIndustryTypes] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    // const [tc, setTC] = useState(false);
    const [storeName, setStoreName] = useState("");
    const [industryType, setIndustryType] = useState(null);
    const [brandName, setBrandName] = useState("");
    const [isStoreAvailable, setIsStoreAvailable] = useState(null);
    const [error, setError] = useState({
        industryType: {
            message: "",
            type: ""
        },
        brandName: {
            message: "",
            type: ""
        },
        storeName: {
            message: "",
            type: ""
        },
        tc: {
            message: "",
            type: ""
        }
    });

    useEffect(() => {
        props.getIndustryTypes();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (industryTypeList) {
            setIndustryTypes(industryTypeList);
        }
    }, [industryTypeList]);

    const onSubmitHandler = async () => {
        // setIsLoading(true);
        let errorInfo = {
            industryType: {
                message: "",
                type: ""
            },
            brandName: {
                message: "",
                type: ""
            },
            storeName: {
                message: "",
                type: ""
            },
            tc: {
                message: "",
                type: ""
            }
        },
            isError = false;
        setIsStoreAvailable(null);
        setError(errorInfo);
        if (!isNotNullOrUndefinedString(storeName)) {
            errorInfo.storeName.type = "error";
            errorInfo.storeName.message = "Fill the domain name";
            isError = true;
        }
        if (!isNotNullOrUndefinedString(brandName)) {
            errorInfo.brandName.message = "Fill the brand name";
            errorInfo.brandName.type = "error";
            isError = true;
        }

        if (!isNotNullOrUndefinedString(industryType)) {
            errorInfo.industryType.message = "Select the industry type";
            errorInfo.industryType.type = "error";
            isError = true;
        }
        if (!isError) {
            const payload = {
                industryType,
                storeName,
                brandName
            };
            const resp = await props.createNewStore(payload);
            if (resp) {
                // setIsLoading(false);
                let popupConfirmDetail = {
                    title: "Store Created",
                    renderContent: () => { return <div style={{ color: "#000", lineHeight: 2 }} >Your store <span style={{ fontWeight: "bold" }}>{storeName}</span> has been created Successfully</div> },
                    okay: {
                        label: "Okay",
                        callback: () => {
                            setPopupConfirm(null)
                            navigateTo("/home");
                        }
                    }
                }
                setPopupConfirm(popupConfirmDetail)
            }
        } else {
            // setIsLoading(false);
            setError(errorInfo);
        }
    }

    const getStoreAvailable = async (sName) => {
        const resp = await props.getStoreAvailable(sName);
        let errorInfo = {
            storeName: {
                message: "",
                type: ""
            }
        };
        setError({ ...error, ...errorInfo });
        if (resp.error) {
            setIsStoreAvailable(false);
        } else if (resp) {
            setIsStoreAvailable(resp.isAvailable);
        }
    }

    // const { t } = props
    return <div className="setupstore_mob" style={{ height: dimensions.height }}>
        <div className="header">
            <div className="logo">
                <label>Create Store </label>
            </div>
        </div>
        <div className="content" style={{ height: dimensions.height - 210, padding: "0px" }}>
            {/* <img src={NoStoreSvg} style={{ height: 187, padding: "0px 30px" }} /> */}
            <div className="create_detail" >
                <div className="group">
                    <label>
                        Brand Name *
                    </label>
                    <input type="text" placeholder="Enter brand name" value={brandName}
                        onChange={event => setBrandName(event.target.value)}
                    />
                    <div className="error" style={{ marginTop: 2, marginLeft: 4 }}>{error.brandName.message}</div>
                </div>
                <div className="group">
                    <label>Domain Name *</label>
                    <input type="text" placeholder="Enter domain name" value={storeName}
                        onChange={event => { setIsStoreAvailable(null); setStoreName(event.target.value.trim()) }}
                        onBlur={event => event.target.value && getStoreAvailable(event.target.value)}
                    />
                    <div className="error" style={{ marginTop: 2, marginLeft: 4 }}>{error.storeName.message}</div>
                    <div style={{ display: 'flex', marginTop: 8 }}>
                        <div style={{ color: isStoreAvailable === true ? '#0076FF' : isStoreAvailable === false ? '#BD1616' : "#000", marginRight: 4 }}>{storeName}.myshopletzy.com </div>
                        {isStoreAvailable === true && <div> is available</div>}
                        {isStoreAvailable === false && <div >is not available</div>}
                    </div>

                </div>

                <div className="group">
                    <label>Industry Type *</label>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        // filterOption={(input, option) =>
                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        // filterSort={(optionA, optionB) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                        placeholder="Select Industry Type"
                        defaultValue={industryType}
                        onChange={(value) => { setIndustryType(value) }}>
                        {industryTypes.map((i) => {
                            return <Option key={i.value} value={i.value}>{i.label.toUpperCase()}</Option>
                        })}
                    </Select>
                    <div className="error" style={{ marginTop: 2, marginLeft: 4 }}>{error.industryType.message}</div>
                </div>
            </div>
        </div>
        <div className="footer">
            <button style={{ marginTop: 16 }} className="sp_btn next-btn" onClick={onSubmitHandler}>Create Store</button>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </div>
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    getIndustryTypes,
    createNewStore,
    getStoreAvailable,
})(withTranslation()(SetupStore));
