import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { getStores } from "../../../actions/setupStoreActions";
// import { selectedStore } from "../../../actions/uiAction";
import { signout } from "../../../actions/commonAction";
// import Header from './Header';
// import Footer from './Footer';
import "../../../assets/sass/mob/setupstore.sass"
// import StoreNotAvailableJson from '../../../assets/lotties/store-at-hitit.json';
// import LottiePlayer from 'lottie-web';

const Home = (props: any) => {
    const windowHeight = window.innerHeight;
    const logout = () => {
        localStorage.setItem("userInfo", "");
        props.signout();
    }
    return (
        <div className="setupstore_mob" style={{ height: (windowHeight) + "px", display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <div style={{ fontSize: 20 }}>
                Coming Soon...,
            </div>
            <div className="content">
                <button onClick={logout}>Logout</button>
            </div>
        </div>
    )
}

export default connect(null, {
    signout
})(withTranslation()(Home));