import SelectStoreTour from "./SelectStoreTour";
import HeaderTour from "./HeaderTour";
import React from "react";
import "../assets/sass/dsk/onboarding.sass";
import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux";

const Onboarding = (props) => {
    const { selectedStore } = useSelector((state: any) => state.ui);
    const location = useLocation();
    return <div>
        {location.pathname === "/selectstore" && <SelectStoreTour />}
        {location.pathname === `/${selectedStore?.name}/home` && <HeaderTour />}
    </div>
}

export default Onboarding;