
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
// import Locale from '../common/Locale';
import '../../../assets/sass/dsk/product.sass';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createProduct, uploadFile, updateProduct, deleteFile } from '../../../actions/productActions';
import { getCategories } from '../../../actions/categoryActions';
import { getOutlets } from '../../../actions/outletAction';
// import { getLocale, setLocale } from '../../../actions/uiAction';
// import { getL10N } from "../../../helpers/middleware";
import { isNotNullOrUndefinedString } from "../../../helpers/util";
import config from "../../../apiConfig";
import { Row, Col, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
// import FilesUpload from '../common/FilesUpload';
// import langIcon from "../../../assets/images/language.svg";
import PopupConfirm from '../common/PopupConfirm';
// import InputValidation from '../common/InputValidation';
// import Loading from '../common/Loading';

const { Option } = Select;
// const text = <span>Language</span>;
// const langList = [{
//     key: "en",
//     value: "English"
// }, {
//     key: "ta",
//     value: "Tamil"
// }]
const SaveProduct = (props: any) => {
    // let action = props.action;
    const windowHeight = window.innerHeight;
    // const [isNew, setIsNew] = useState(props.selectedProduct ? false : true);
    const categoryList = useSelector((state: any) => state.category.categories);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [categories, setCategories] = useState([]);
    // const [categorieOptions, setCategorieOptions] = useState([]);
    // const [tempDeleteImages, setTempDeleteImages] = useState([]);
    // const [selectedLang, setSelectedLang] = useState(langList[0]);
    const [outlets, setOutlets] = useState([]);
    const [images, setImages] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const [summary, setSummary] = useState("");
    const [productLabels, setProductLabels] = useState([]);
    const [sku, setSku] = useState("");
    // const [subtitle, setSubTitle] = useState("");
    // const [title, setTitle] = useState("");
    const [product, setProduct] = useState({ id: null, title: "", subtitle: "", l10n: {}, sku: "", outlets: [] });
    // const [visibleTitleLang, setVisibleTitleLang] = useState(false);
    // const [visibleSubTitleLang, setVisibleSubTitleLang] = useState(false);
    let errorInfo = {
        sku: {
            message: "",
            type: ""
        },
        title: {
            message: "",
            type: ""
        },
        subtitle: {
            message: "",
            type: ""
        },
        categories: {
            message: "",
            type: ""
        }
    };
    // const [error, setError] = useState(errorInfo);
    // const [productLang, setProductLang] = useState({
    //     subtitleLang: langList[0].key,
    //     titleLang: langList[0].key,
    // });

    const [isEdit, setIsEdit] = useState(false);


    // const [subtitleLang, setSubtitleLang] = useState(selectedLang.key);
    // const [titleLang, setTitleLang] = useState(selectedLang.key);

    useEffect(() => {
        let categoryOptions = []
        for (let i in categoryList) {
            let searchRegExp = /\//g;
            let replaceWith = ' / ';
            categoryOptions.push(<Option key={categoryList[i].category.replace("/", "")} value={categoryList[i].category} >{categoryList[i].category.replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase())}</Option>)
        }
        // setCategorieOptions(categoryOptions);
    }, [categoryList]);

    const loadOutlets = async () => {
        let respOutlets = await props.getOutlets();
        if (respOutlets) {
            setOutlets(respOutlets);
        } else {
            setOutlets([]);
        }
    }

    useEffect(() => {
        if (props.selectedProduct) {
            loadOutlets();
            setProduct(props.selectedProduct);
            // setTitle(getL10N(props.selectedProduct, "title", selectedLang).i10n);
            // setSubTitle(getL10N(props.selectedProduct, "subtitle", selectedLang).i10n);
            if (props.selectedProduct.sku) {
                setSku(props.selectedProduct.sku);
            }
            if (props.selectedProduct.description) {
                setSummary(props.selectedProduct.description);
            }
            if (props.selectedProduct.images) {
                let index = 0;
                for (let i in props.selectedProduct.images) {
                    images.push({
                        isUploaded: true,
                        uid: index++,
                        name: 'image.png',
                        status: 'done',
                        url: `${config.baseImageUrl}${i}`,
                    });
                }
                setImages(images);
            }
            if (props.selectedProduct.categories) {
                setCategories(props.selectedProduct.categories);
            } else {
                setCategories([]);
            }
            if (props.selectedProduct.labels) {
                setProductLabels(props.selectedProduct.labels);
            }
        }
        //eslint-disable-next-line
    }, [props.selectedProduct && props.selectedProduct.id]);

    // useEffect(() => {
    // console.log(productLang);
    // }, [productLang]);

    // const renderLang = (fieldName) => {
    //     const content = [];
    //     for (let i in langList) {
    //         content.push(
    //             <div key={langList[i].key} style={{ color: productLang[`${fieldName}Lang`] == langList[i].key ? "#37a2fd" : "#000000", fontWeight: productLang[`${fieldName}Lang`] == langList[i].key ? 800 : 100 }}>
    //                 <p onClick={() => onChangeLang(langList[i], fieldName)}>{langList[i].value}</p>
    //             </div>
    //         );
    //     }
    //     return content;
    // }

    // const onChangeLang = (selectLang, fieldName) => {
    //     // console.log(selectLang, fieldName)
    //     if (product) {
    //         let { i10n, isDefault } = getL10N(product, fieldName, selectLang);
    //         if (fieldName == "title") {
    //             // setTitle(isDefault ? "" : i10n);
    //         }
    //         if (fieldName == "subtitle") {
    //             // setSubTitle(isDefault ? "" : i10n);
    //         }
    //     }
    //     // let updateLang = { [`${fieldName}Lang`]: selectedLang.key } };
    //     // console.log(updateLang)
    //     setProductLang((preState) => ({ ...preState, ...{ [`${fieldName}Lang`]: selectLang.key } }));
    //     // setVisibleTitleLang(false);
    //     // setVisibleSubTitleLang(false);
    //     // setSelectedLang((preState) => ({ ...preState, ...selectLang }))
    // }



    // const onChangeCategory = (value) => {
    //     console.log(value)
    //     setCategories(value)
    // }

    const onSubmit = () => {

        let isError = false;
        let payload = {
            title: "",
            subtitle: "",
            categories: null,
            sku: "",
            labels: []
        };
        // this.setState({ error });
        if (!isNotNullOrUndefinedString(sku)) {
            errorInfo.sku.message = "Enter the SKU details";
            errorInfo.sku.type = "error";
            isError = true;
        } else {
            errorInfo.sku.message = "";
            errorInfo.sku.type = "";
        }

        if (!isNotNullOrUndefinedString(product.title)) {
            errorInfo.title.message = "Enter the product title";
            errorInfo.title.type = "error";
            isError = true;
        } else {
            errorInfo.title.message = "";
            errorInfo.title.type = "";
        }
        if (!isNotNullOrUndefinedString(product.subtitle)) {
            errorInfo.subtitle.message = "Enter the product sub title";
            errorInfo.subtitle.type = "error";
            isError = true;
        } else {
            errorInfo.subtitle.message = "";
            errorInfo.subtitle.type = "";
        }

        if (categories.length === 0) {
            errorInfo.categories.message = "Select any one of category";
            errorInfo.categories.type = "error";
            isError = true;
        } else {
            errorInfo.categories.message = "";
            errorInfo.categories.type = "";
        }
        // setError((preState) => ({ ...preState, ...errorInfo }));
        if (!isError) {
            payload.title = product.title;
            payload.subtitle = product.subtitle;
            payload.labels = productLabels;
            if (summary) {
                payload["description"] = summary;
            }
            if (product.l10n) {
                payload["l10n"] = product.l10n;
            }
            payload.sku = sku;
            payload.categories = categories;
            // if (product.id) {
            //     payload["outlets"] = product.outlets;
            //     this.uploadImages(product.id);
            // } else {
            //     if (this.props.task.selectedOutlets) {
            //         payload["outlets"] = this.props.task.selectedOutlets
            //     }
            // }
            let popupConfirmDetail = null;
            if (product.id) {
                delete payload.sku;
                popupConfirmDetail = {
                    title: "Update Product",
                    renderContent: () => { return <div>Do you like to update {product.title} SKU <span style={{ fontWeight: 900, font: "14px AvenirMedium", letterSpacing: "0px", color: "#34E5C1" }}>{product.sku}</span></div> },
                    yes: {
                        label: "Update Product",
                        callback: () => { onSaveProduct(product.id, payload); }
                    },
                    no: {
                        label: "May be next time",
                        callback: () => { setPopupConfirm(null) }
                    }
                }
                setPopupConfirm(popupConfirmDetail)
            } else {
                popupConfirmDetail = {
                    title: "Add Product",
                    renderContent: () => { return <div>Do you like to add new {product.title} SKU <span style={{ fontWeight: 900, font: "14px AvenirMedium", letterSpacing: "0px", color: "#34E5C1" }}>{product.sku}</span></div> },
                    yes: {
                        label: "Add Product",
                        callback: () => { onSaveProduct(null, payload); }
                    },
                    no: {
                        label: "May be next time",
                        callback: () => { setPopupConfirm(null) }
                    }
                }
            }
            setPopupConfirm(popupConfirmDetail)

        }
    }

    const onSaveProduct = async (pId, payload) => {
        // setIsLoading(true);
        setPopupConfirm(null)
        // let resp = null;
        if (pId) {
            await props.updateProduct(pId, payload)
        } else {
            await props.createProduct(payload);
        }
        // setIsLoading(false);
    }

    // const uploadImages = async (pId) => {
    //     let bodyFormData = new FormData();
    //     let fileUploaded = null;
    //     let fileDeleted = null;
    //     let isUpload = false;
    //     for (let item in images) {
    //         let { originFileObj, isUploaded }: any = images[item];
    //         if (!isUploaded && originFileObj) {
    //             isUpload = true;
    //             bodyFormData.append('images', originFileObj);
    //         }
    //     }
    //     if (isUpload) {
    //         fileUploaded = await props.uploadFile(pId, bodyFormData);
    //     } else {
    //         fileUploaded = true;
    //     }
    //     if (tempDeleteImages.length > 0) {
    //         fileDeleted = await props.deleteFile(product.id, {
    //             filenames: tempDeleteImages
    //         });
    //     } else {
    //         fileDeleted = true;
    //     }
    //     console.log('fileUploaded', fileUploaded);
    //     console.log('fileDeleted', fileDeleted);
    //     setIsLoading(false);
    //     setPopupConfirm(null);
    //     if (fileUploaded && fileDeleted) {
    //         props.onClose();
    //     }
    // }

    // const removeImage = (file) => {
    //     setTempDeleteImages(preState => [...preState, file.url.replace(config.baseImageUrl, "")]);
    // }


    // useEffect(() => {
    //     if (props.product.saveProduct && props.product.saveProduct.productId) {
    //         uploadImages(props.product.saveProduct.productId);
    //     }
    // }, [props.product && props.product.saveProduct]);

    // const setProductLocale = (key, val, lang) => {
    //     console.log(key, val, lang)
    //     if (!product) {
    //         // setProduct((preState) => ({}))
    //         setProduct((preState) => ({ ...preState, ...{} }));
    //     }
    //     if (lang == "en") {
    //         if (product) {
    //             if (!product[key]) {
    //                 product[key] = {}
    //             }
    //             product[key] = val;
    //         }
    //     } else {
    //         if (!product.l10n) {
    //             product.l10n = {};
    //         }
    //         if (!product.l10n[lang]) {
    //             product.l10n[lang] = {}
    //         }
    //         if (!product.l10n[lang][key]) {
    //             product.l10n[lang][key] = {}
    //         }
    //         product.l10n[lang][key] = val;
    //     }
    //     setProduct(product)
    // }

    useEffect(() => {
        if (props.isVisible === false) {
            setPopupConfirm(null);
            setCategories([]);
            // setCategorieOptions([]);
            // setSelectedLang(langList[0]);
            setOutlets([]);
            setImages([]);
            setSummary("");
            setSku("");
            // setSubTitle("");
            // setTitle("");
            setProduct({ id: null, title: "", subtitle: "", l10n: {}, sku: "", outlets: [] });
            // setVisibleTitleLang(false);
            // setVisibleSubTitleLang(false);
        } else {
            setIsEdit(props.action === "new" ? true : false);
            props.getCategories();
        }
        //eslint-disable-next-line
    }, [props.isVisible]);
    const renderOutletName = (ouId) => {
        let getOutletInfo = outlets.filter((item) => (item.id === ouId));
        return getOutletInfo.length > 0 ? getOutletInfo[0].name : "Not Available";
    }
    return (
        <>
            {/* <Drawer
             width={"50%"}
             closable={false}
             onClose={props.onClose}
             className="product-view"
             visible={props.isVisible}> */}
            {/* {isLoading && <Loading background="#00000070"></Loading>}         */}
            <Row className="content" >
                <Col span={24} className="details" style={{ height: windowHeight - 148 }}>
                    <Row className="basic-info">
                        <Col span={24} style={{ textTransform: "uppercase" }}>
                            <ArrowLeftOutlined style={{
                                position: "absolute",
                                cursor: "pointer",
                                marginLeft: "-44px"
                            }} onClick={() => props.onClose()}></ArrowLeftOutlined>
                            Product Details
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Title *</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.title.type} message={error.title.message}>
                                        <Input className="value" suffix={
                                            <Popover placement="rightTop" visible={visibleTitleLang} onVisibleChange={() => {
                                                setVisibleTitleLang(!visibleTitleLang);
                                                setVisibleSubTitleLang(false)
                                            }} title={text} content={renderLang("title")} trigger="click">
                                                <img src={langIcon} />
                                            </Popover>
                                        } value={title} disabled={!isEdit} onChange={(e) => {
                                            setTitle(e.target.value);
                                            setSku(e.target.value.replace(/ /g, "_").toLocaleUpperCase());
                                            setProductLocale("title", e.target.value, productLang.titleLang);
                                            renderLang("title");
                                        }} />
                                    </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Sub Title *</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.subtitle.type} message={error.subtitle.message}>

                                        <Input className="value" suffix={
                                            <Popover placement="rightTop" visible={visibleSubTitleLang} onVisibleChange={() => {
                                                setVisibleTitleLang(false);
                                                setVisibleSubTitleLang(!visibleSubTitleLang)
                                            }} title={text} content={renderLang("subtitle")} trigger="click">
                                                <img src={langIcon} />
                                            </Popover>
                                        } disabled={!isEdit} value={subtitle} onChange={(e) => { setSubTitle(e.target.value); setProductLocale("subtitle", e.target.value, productLang.subtitleLang) }} />
                                    </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Category *</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.categories.type} message={error.categories.message}>
                                        <Select disabled={!isEdit}
                                            className="value"
                                            defaultValue={categories}
                                            mode="multiple"
                                            onChange={onChangeCategory}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }>
                                            {
                                                categoryList && categoryList.map((item, i) => {
                                                    let searchRegExp = /\//g;
                                                    let replaceWith = ' / ';
                                                    return <Option key={item.category.replace("/", "") + "_" + i} value={item.category} >{item.category.replace("/", "").replace(searchRegExp, replaceWith).replace(/\b\w/g, l => l.toUpperCase())}</Option>
                                                })
                                            }
                                        </Select>
                                    </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Product SKU</div>
                                </Col>
                                <Col span={24}>
                                    {/* <InputValidation type={error.sku.type} message={error.sku.message}>
                                        <Input className="value" disabled={true} value={sku} />
                                    </InputValidation> */}
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Product Summary</div>
                                </Col>
                                <Col span={24}>
                                    <TextArea className="value" disabled={!isEdit} value={summary} onChange={(e) => setSummary(e.target.value)} rows={6} />
                                </Col>
                            </Row>
                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Product Labels</div>
                                </Col>
                                <Col span={24}>
                                    <Select mode='multiple' defaultValue={productLabels} value={productLabels} disabled={!isEdit} onChange={(newValue: string[]) => { setProductLabels(newValue) }} maxTagCount='responsive' placeholder='Select product labels...' >
                                        <Option value="topPick">Top Pick</Option>
                                        <Option value="bestSeller">Best Seller</Option>
                                        <Option value="whatsNew">Whats New</Option>
                                    </Select>
                                </Col>
                            </Row>

                            <Row className="group">
                                <Col span={24}>
                                    <div className="label" >Attach to a outlet</div>
                                </Col>
                                <Col span={24}>
                                    <Select mode='multiple' defaultValue={productLabels} value={productLabels} disabled={!isEdit} onChange={(newValue: string[]) => { setProductLabels(newValue) }} maxTagCount='responsive' placeholder='Select one or more outlets' >
                                        <Option value="topPick">Outlet 1</Option>
                                        <Option value="bestSeller">Outlet 2</Option>
                                        <Option value="whatsNew">Outlet 3</Option>
                                    </Select>
                                </Col>
                            </Row>

                        </Col>
                        <Col span={2}>
                        </Col>
                        <Col span={11}>
                            <Row className="group" >
                                <Col span={24}>
                                    <div className="label" >Product Images</div>
                                </Col>
                                <Col span={24} style={{ height: windowHeight - 580, marginTop: 12 }}>
                                    {/* <FilesUpload isEdit={isEdit} defaultValue={images} multiple={true} removeImage={removeImage} onChange={(img) => setImages(img)}></FilesUpload> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="group" >
                        <Col span={24}>
                            <div className="label" >Product Attached Outlets</div>
                        </Col>
                        <Col span={24}>
                            {product.outlets && product.outlets.length > 0 && <div style={{ display: "flex", marginLeft: 4 }}>{product.outlets.map((item) => {
                                const name = renderOutletName(item.ouId);
                                return <div title={name} style={{
                                    height: 26,
                                    background: '#d6e6f2',
                                    color: '#000',
                                    borderRadius: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 10,
                                    padding: "0px 10px"
                                }}>{name}</div>
                            })}</div>}
                            {(!product.outlets || product.outlets.length === 0) && <div style={{ color: '#d6e6f2' }}>No Outlet Available</div>}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="action-bar">
                    <Row>
                        <Col span={13}></Col>
                        <Col span={5} style={{ paddingRight: "15px" }}>
                            <button onClick={() => props.onClose()} className="sp_btn cancel">Cancel</button>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5} style={{ paddingLeft: "15px" }}>
                            {isEdit && <button onClick={onSubmit} className="sp_btn save">{props.selectedProduct ? "Update Product" : "Create Product"}</button>}
                            {!isEdit && <button onClick={() => setIsEdit(true)} className="sp_btn edit">Edit</button>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        </>
        // </Drawer>
    )
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCategories,
        getOutlets,
        createProduct,
        uploadFile,
        updateProduct,
        deleteFile
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(SaveProduct);
