import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "react-alice-carousel/lib/alice-carousel.css";
import { getStores } from "../../../actions/setupStoreActions";
import { selectedStore } from "../../../actions/uiAction";
// import Header from './Header';
// import Footer from './Footer';
import "../../../assets/sass/dsk/setupstore.sass";
import StoreNotAvailableJson from "../../../assets/lotties/store-at-hitit.json";
import LottiePlayer from "lottie-web";
import config from "../../../apiConfig";
import { b64DecodeUnicode } from "../../../helpers/util";
import { useNav } from "../../../helpers/customHook";

const SelectStore = (props: any) => {
    const navigateTo = useNav();
    const windowHeight = window.innerHeight;
    // const responsive = {
    //     0: {
    //         items: 2,
    //     },
    //     1024: {
    //         items: 10,
    //     },
    // };
    const [stores, setStores] = useState(null);
    // const handleDragStart = (e) => e.preventDefault();
    const storeNotAvailableRef = useRef(null);
    const onSelectedStore = async (store) => {
        await props.selectedStore(store);
        navigateTo("/home");
    };
    useEffect(() => {
        getStoreDetails();
        //eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     let items = [];
    //     if (stores) {
    //         for (const store of stores) {
    //             items.push(<div className="store" onClick={() => { onSelectedStore(store) }} onDragStart={handleDragStart} >
    //                 <div className="item" title={store.brandName}>
    //                     <div className="letter">{store.brandName.charAt(0).toUpperCase()}</div>
    //                 </div>
    //                 <div className="name">{store.brandName}</div>
    //                 <div className="">{store.userType}</div>
    //             </div>)
    //         }
    //     }
    //     setStoreCards(items);
    // }, [stores]);
    const getStoreDetails = async () => {
        const resp = await props.getStores();
        if (!resp || resp.length === 0) {
            LottiePlayer.loadAnimation({
                container: storeNotAvailableRef.current,
                renderer: "svg",
                loop: false,
                autoplay: true,
                animationData: StoreNotAvailableJson,
            });
        }
        setStores(resp);
    };

    // const onCreateStore = () => {
    //     navigateTo("/setupstore");
    // };

    const getStoreLogo = (store: any) => {
        const settings = store.settings;
        if (settings && settings.theme && settings.theme.web) {
            const web = JSON.parse(b64DecodeUnicode(settings.theme.web));
            if (web.brand.url) {
                return config.baseImageUrl + web.brand.url;
            }
        }
    };

    return (
        <div className="setupstore_mob" style={{ height: windowHeight + "px" }}>
            {/* <Header /> */}
            <div className="content" style={{ padding: "30px" }}>
                <div className="title">Your Stores</div>
                <div className="staff">
                    {stores && stores.length > 0 && (
                        <>
                            {stores.map((store) => {
                                const imageUrl = getStoreLogo(store);
                                return (
                                    <div key={store} onClick={() => onSelectedStore(store)} className="store_card">
                                        {!imageUrl && <div className="letter">{store.name.charAt(0).toUpperCase()}</div>}
                                        {imageUrl && <div className="logo_container"><img alt="Logo" className="logo" src={imageUrl} /></div>}
                                        <div className="detail">
                                            <div className="name">{store.name}</div>
                                            <div className={`type_${store.userType.toLowerCase()}`}>
                                                {store.userType}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                    <div className="no_store" ref={storeNotAvailableRef}></div>
                    {(!stores || stores.length === 0) && (
                        <>
                            {" "}
                            <div
                                className="subtitle"
                                style={{
                                    width: "70%",
                                    marginTop: 26,
                                    textAlign: "center",
                                    zIndex: 90,
                                }}
                            >
                                You are not part of any store yet. Tell your store admin to grant you access
                            </div>
                            {/* <div className="store_create_title">If you are the store owner</div>
                        <button style={{ zIndex: 99, cursor: 'pointer' }} onClick={onCreateStore}>
                            Create New Store
                        </button> */}
                        </>
                    )}
                </div>
            </div>
            {/* <Footer></Footer> */}
        </div>
    );
};

export default connect(null, {
    getStores,
    selectedStore,
})(withTranslation()(SelectStore));
