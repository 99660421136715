import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { oauthLogin, signup } from "../../../actions/authActions";
import { selectedStore } from "../../../actions/uiAction";
import { getStores } from "../../../actions/setupStoreActions";
// import Locale from "../common/Locale";
import '../../../assets/sass/mob/signin.sass';
import GooglePng from "../../../assets/images/google_icon.png";
import { withTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
// import PopupConfirm from '../common/PopupConfirm';
import { CONSTANTS } from '../../../constants';
import logo from "../../../assets/images/ShopLetzy_logo_wh_s1.svg";
import PopupConfirm from '../common/PopupConfirm';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNav } from '../../../helpers/customHook';

const SignIn = (props: any) => {
    const dispatch: any = useDispatch();
    const navigateTo = useNav();
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const onSignin = async (response) => {
        const resp = dispatch(oauthLogin(response));
        if (resp && resp.isMobileVerified && resp.mobileNo) {
            const stores = dispatch(getStores());
            if (stores) {
                if (stores.length > 0) {
                    navigateTo("/selectstore")
                } else {
                    navigateTo("/products")
                }
            } else {
                navigateTo("/setupstore")
            }

        } else if (resp.status === "unregistered" || (resp && resp.isMobileVerified === false)) {
            // props.history.push("/signup");
            let popupConfirmDetail = null;
            popupConfirmDetail = {
                title: "Account Creation",
                renderContent: () => {
                    return <div>
                        <div>You do not have any account created with us yet. Do you want to create new account?</div>
                        <div style={{ marginTop: 14 }}>By creating an account with Shopletzy, you are agreeing to our <div onClick={() => window.open(CONSTANTS.Urls.tc)} style={{ color: '#0076FF', cursor: 'pointer' }}>terms and conditions</div></div>
                    </div>
                },
                yes: {
                    label: "Yes",
                    callback: async () => {
                        const resp = dispatch(signup(response));
                        if (resp && resp.token) {
                            navigateTo("/sendOTP");
                        }
                    }
                },
                no: {
                    label: "No",
                    callback: () => { setPopupConfirm(null) }
                }
            }
            setPopupConfirm(popupConfirmDetail)
        }
    }

    const responseGoogle = async (response) => {
        if (response.tokenId) {
            response.authProvider = "google";
            await onSignin(response);
        }
    }

    const responseFacebook = async (response) => {
        if (response.accessToken) {
            response.tokenId = response.accessToken;
            response.authProvider = "facebook";
            await onSignin(response);
        }
    }

    return <div className="signin_mob" style={{ height: dimensions.height }}>
        <div className="header">
            <div onClick={() => window.open(CONSTANTS.Urls.pp)}>Privacy Policy</div>
            <div onClick={() => window.open(CONSTANTS.Urls.tc)}>Terms & Conditions</div>
        </div>
        <div style={{ flexGrow: 1, height: dimensions.height - 70 }}>
            <div className="company">
                <div className="logo">
                    <img alt='Logo' src={logo} />
                    <label>Shopletzy</label>
                </div>
                <div className="motto">
                    Democratizing eCommerce
                </div>
                <div className="desc">
                    Get your own branded online shopping website It costs you nothing to get started
                </div>
            </div>
            <div className="oauth">
                {/* <button onClick={debugLogin}>Debug Login</button> */}
                <GoogleLogin
                    clientId="80195849052-g354619gb9nithq0nqhaapl94g51u9hv.apps.googleusercontent.com"
                    render={renderProps => (
                        <button onClick={renderProps.onClick} className="google" disabled={renderProps.disabled}>
                            <img alt='Google' src={GooglePng}></img>
                            <label>Login with Google</label>
                        </button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                />
                <FacebookLogin
                    appId="2105590542907033"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="fb"
                    icon="fa-facebook"
                    render={renderProps => (
                        <button onClick={renderProps.onClick}>
                            <span> Login with Facebook</span>
                        </button>
                    )}
                />
            </div>
        </div>
        <div className="footer">
            Copyright @ {dayjs().format('YYYY')} Shopletzy Private Limited
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </div>
}

function mapStateToProps(state: any) {
    // console.log("SignIn mapStateToProps: ", state);
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { oauthLogin, signup, getStores, selectedStore })(withTranslation()(SignIn));
