import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { selectedTabMenu as onSelectedTabMenu } from "../../../actions/uiAction";
import "../../../assets/sass/mob/products.sass"
import ActionPanel from '../common/ActionPanel';
import { CaretDownOutlined, CloseCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import ProductCatalog from './ProductCatalog';
import ListedProduct from './ListedProduct';
import SaveProduct from './SaveProduct';

const Products = (props: any) => {
    const [selectedTabMenu, setSelectedTabMenu] = useState("listed_products");
    const [isProductTabOpen, setProductTabOpen] = useState(false);
    const [isSaveTabOpen, setSaveTabOpen] = useState(false);
    const { selectedTabMenu: selectedTabMenuUI } = useSelector((state: any) => state.ui);
    const outlets = useSelector((state: any) => state.outlet.outlets);
    const [selectedOutlet, setSelectedOutlet] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        if (selectedTabMenuUI !== selectedTabMenu) {
            setSelectedTabMenu(selectedTabMenuUI)
        }
        //eslint-disable-next-line
    }, [selectedTabMenuUI])
    useEffect(() => {
        if (selectedTabMenu) {
            props.onSelectedTabMenu(selectedTabMenu);
        }
        //eslint-disable-next-line
    }, [selectedTabMenu]);
    useEffect(() => {
        if (props.selectedOutlet !== selectedOutlet) {
            setSelectedOutlet(props.selectedOutlet);
        }
        //eslint-disable-next-line
    }, [props.selectedOutlet]);

    useEffect(() => {
        if (outlets && outlets.length > 0) {
            setSelectedOutlet(outlets[0].id);
        }
        //eslint-disable-next-line
    }, [outlets]);
    return (
        <div className="products">
            <div className="header">
                <div className="selected-menu" onClick={() => setProductTabOpen(true)}>
                    {selectedTabMenu && selectedTabMenu.replace('_', ' ')}
                    <CaretDownOutlined style={{ marginLeft: 6, fontSize: 22 }} />
                </div>
                <PlusSquareOutlined style={{ fontSize: 32 }} onClick={() => { setSelectedProduct(null); setSaveTabOpen(true) }} />
            </div>
            {selectedTabMenu === 'listed_products' && <ListedProduct></ListedProduct>}
            {selectedTabMenu === 'product_catalog' && <ProductCatalog></ProductCatalog>}
            <ActionPanel onOpen={isProductTabOpen} size={30}>
                <div className="menu">
                    <div className="title">Choose Product Tab</div>
                    <div onClick={() => setProductTabOpen(false)}>
                        <CloseCircleOutlined style={{ fontSize: 20 }} />
                    </div>
                </div>
                <div className="items">
                    <div className="tab-name" style={{ color: selectedTabMenu === 'listed_products' ? '#33E1BE' : '#FFF' }} onClick={() => { setSelectedTabMenu("listed_products"); setProductTabOpen(false); }}>
                        Listed Products
                    </div>
                    <div className="tab-name" style={{ color: selectedTabMenu === 'product_catalog' ? '#33E1BE' : '#FFF' }} onClick={() => { setSelectedTabMenu("product_catalog"); setProductTabOpen(false); }}>
                        Product Catalog
                    </div>
                </div>
            </ActionPanel>
            <ActionPanel onOpen={isSaveTabOpen} size={100} position="right">
                <SaveProduct selectedOutlet={selectedOutlet} selectedProduct={selectedProduct} onClose={() => setSaveTabOpen(false)}></SaveProduct>
            </ActionPanel>
        </div>
    )
}

export default connect(null, {
    onSelectedTabMenu
})(withTranslation()(Products));