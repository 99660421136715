import React, { useEffect, useState } from 'react';
import Header from '../setupstore/Header';
import Footer from '../setupstore/Footer';
import { useDispatch } from 'react-redux';
import '../../../assets/sass/dsk/signin.sass';
import { otpLogin } from "../../../actions/authActions";
import Loading from '../common/Loading';
import { isMail } from '../../../helpers/util';
import { useNav } from '../../../helpers/customHook';

const EmailLogin = () => {
    const dispatch: any = useDispatch();
    const navigateTo = useNav();
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const sendOTP = async () => {
        setError(null);
        if (!isMail(email)) {
            setError("Enter the valid mail address");
        } else {
            setLoading(true);
            const resp = await otpLogin(email)(dispatch);
            if (resp?.status === "unregistered") {
                setError("Email id does not exists.")
            } else if (resp?.token) {
                navigateTo(`/verifyotp/${email}`);
            }
            setLoading(false);
        }
    }
    return <div className="email" style={{ height: dimensions.height }}>
        <Header disableLogin={true} />
        <div className="content">
            <div className="signin">
                <div className='header'>
                    <div className="title">
                        Sign In to Get
                    </div>
                    <div className='code'>
                        Code
                    </div>
                </div>
                <div className="title">
                    <div>We will send you an</div> <div className='title-plus'>One Time Password</div> <div>to this email address</div>
                </div>
                <input className="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Enter your email / mobile number" />
                {error && <div className='error'>{error}</div>}
                <div className="send-otp" onClick={sendOTP}>Send OTP</div>
                <div className='new-reg'>
                    <div className="new">
                        New to Shopletzy?
                    </div>
                    <div className='reg' onClick={() => navigateTo("/emailsignup")}>
                        Register Now
                    </div>
                </div>
            </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Footer />
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
    </div>
}
export default EmailLogin;