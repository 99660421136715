import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { downloadInvoice, getInvoices } from "../../../actions/billingAction";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DownloadSvg from "../../../assets/images/download-invoice.svg";

const Invoice = () => {
    const dispatch = useDispatch();
    const WindowHeight = window.innerHeight;
    const [invoiceList, setInvoiceList] = useState(null);
    const getInvoiceList = async () => {
        const response = await getInvoices()(dispatch);
        if (response && response.invoices) {
            setInvoiceList(response.invoices);
        }
    }
    const onDownloadInvoice = async (invoice) => {
        const resp = await downloadInvoice(null, invoice.id)(dispatch);
        if (resp && resp?.url) {
            const link = document.createElement('a');
            link.href = resp?.url;
            link.target = '_blank';
            link.download = `${invoice.id}_invoice.pdf`;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }
    }
    useEffect(() => {
        getInvoiceList();
        // eslint-disable-next-line
    }, []);
    return <div className="invoice">
        <Row style={{ width: '100%' }}>
            <Col span={24}>
                {invoiceList && invoiceList.length > 0 && <div className="content" >
                    <div style={{
                        color: '#5A5A5A',
                        background: '#F2F2F2',
                        marginRight: 8,
                        marginLeft: 8,
                        padding: '0px 10px',
                        marginTop: 8,
                    }}>
                        <Row className="header" style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderRadius: 6,
                            height: 53, display: 'flex', alignItems: 'center'
                        }}>
                            <Col span={4}>
                                Invoice No
                            </Col>
                            <Col span={4}>
                                Invoice Date
                            </Col>
                            <Col span={4}>
                                Total Amount
                            </Col>
                            <Col span={4}>
                                Amount Due
                            </Col>
                            <Col span={2}>
                                Status
                            </Col>
                            <Col span={4}>
                                Due Date
                            </Col>
                            <Col span={2}>
                            </Col>
                        </Row>
                    </div>
                    <div style={{
                        marginRight: 8,
                        padding: '0px 10px',
                        marginLeft: 8,
                    }}>
                        <Row className="contains" style={{ margin: 0, height: window.innerHeight - 180, overflowY: 'auto' }}>
                            {invoiceList.map((invoice, index) => {
                                return <Row key={index} className="invoice" style={{
                                    color: '#5A5A5A',
                                    fontWeight: 500,
                                    background: '#FFF',
                                    fontSize: 14,
                                    borderRadius: 0,
                                    height: 53, display: 'flex', alignItems: 'center'
                                }}>
                                    <Col span={4}>
                                        {invoice.invoiceNo}
                                    </Col>
                                    <Col span={4}>
                                        {dayjs(invoice.invoiceDate).format('ddd')}, {dayjs(invoice.invoiceDate).format('MMM')} {dayjs(invoice.invoiceDate).format('DD')},{dayjs(invoice.invoiceDate).format('YYYY')}
                                    </Col>
                                    <Col span={4}>
                                        {invoice.amount}
                                    </Col>
                                    <Col span={4}>
                                        {invoice.amountDue}
                                    </Col>
                                    <Col span={2} className={`status ${invoice.status}`}>
                                        {invoice.status}
                                    </Col>
                                    <Col span={4}>
                                        {dayjs(invoice.dueDate).format('ddd')}, {dayjs(invoice.dueDate).format('MMM')} {dayjs(invoice.dueDate).format('DD')},{dayjs(invoice.dueDate).format('YYYY')}
                                    </Col>
                                    <Col span={2} style={{ 'display': 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={DownloadSvg} alt="download invoice" onClick={() => onDownloadInvoice(invoice)} />
                                    </Col>
                                </Row>
                            })}
                        </Row>
                    </div>
                </div>}
                {invoiceList && invoiceList.length === 0 && <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: WindowHeight - 200, flexDirection: 'column', width: '100%' }}>
                    <div style={{ fontWeight: 900, fontSize: 18, color: '#000000', marginBottom: 20 }}>Welcome to Shopletzy Invoice</div>
                    <div style={{ fontWeight: 900, fontSize: 16, color: '#000000', marginBottom: 33 }}>You have no Invoice yet...</div>
                </div>}
            </Col>
        </Row>
    </div>
}

export default Invoice;