import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { EllipsisOutlined } from "@ant-design/icons";
import { getProducts } from '../../../actions/productActions';
import { getOutlets } from '../../../actions/outletAction';
// import { getLocale, setLocale } from '../../../actions/uiAction';
// import { getL10N, getOutletDetail, loadOutletBasedProduct } from "../../../helpers/middleware";
// import { Row, Col, Table, Select, Input, Button, Badge, Popover } from 'antd';
// import config from '../../../apiConfig';
// import SearchIcn from "../../../assets/images/search.svg"
// import viewIcn from '../../../assets/images/view.svg';
// import ImageNotLoaded from "../../../assets/images/not-found.png";
// import NoImageAvailableIcn from "../../../assets/images/No-image-available.png";
// import SortUpSvg from "../../../assets/images/sort_up.svg";
// import SortDownSvg from "../../../assets/images/sort_down.svg";
// import deleteIcn from '../../../assets/images/delete.png';
// import editIcn from '../../../assets/images/edit.png';
// import Save from './Save';
// import dayjs from 'dayjs';
import '../../../assets/sass/mob/products.sass';

const ProductCatalog = (props: any) => {

    return <div className="product">PC</div>
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getProducts,
        getOutlets
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ProductCatalog);
