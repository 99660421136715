import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
// import Locale from "../common/Locale";
import '../../../assets/sass/mob/signin.sass';
import { withTranslation } from 'react-i18next';
import type from "../../../types/actionTypes";
import logo from "../../../assets/images/ShopLetzy_logo_wh_s1.svg";
import { CONSTANTS } from '../../../constants';
import dayjs from 'dayjs';
import { useNav } from '../../../helpers/customHook';

const UserType = (props: any) => {
    const navigateTo = useNav();
    // const [mobileNumber, setMobileNumber] = useState("");
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const dispatch = useDispatch();
    const onSubmit = (userTypeLogin) => {
        if (userTypeLogin) {
            dispatch({
                type: type.USER_TYPE,
                data: {
                    userTypeLogin
                }
            });
            if (userTypeLogin === "owner") {
                navigateTo("/setupstore");
            } else {
                navigateTo("/staff");
            }
        }
    }
    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    // const { t } = props
    return <div className="signin_mob" style={{ height: dimensions.height }}>
        <div className="header">
            <div onClick={() => window.open(CONSTANTS.Urls.pp)}>Privacy Policy</div>
            <div onClick={() => window.open(CONSTANTS.Urls.tc)}>Terms & Conditions</div>
        </div>
        <div style={{ flexGrow: 1 }}>
            <div className="company">
                <div className="logo">
                    <img alt='Logo' src={logo} />
                    <label>Shopletzy</label>
                </div>
                <div className="motto">
                    Democratizing eCommerce
                </div>
                <div className="desc">
                    Get your own branded online shopping website It costs you nothing to get started
                </div>
            </div>
            <div className="oauth">
                <button onClick={() => onSubmit("owner")}>
                    I’m the store owner
                </button>
                <button onClick={() => onSubmit("staff")}>
                    I’m working for a store
                </button>
            </div>
        </div>
        <div className="footer">
            Copyright @ {dayjs().format('YYYY')} Shopletzy Private Limited
        </div>
    </div>
}

export default connect(null, {
})(withTranslation()(UserType));
