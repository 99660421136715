const PurchaseProductCatalog = ({ numberProducts, setNumberProducts, error = null, onProceed, onCancel }) => {
    return <div className="purchase-product-catalog">
        <div className="container">
            <div className="box">
                <div className="title">Purchase Product Catalog Setup</div>
                <div className="content">
                    <div className="pp-title">Get your store’s product catalog up and running quickly with our Product Catalog Setup service.</div>
                    <div style={{ display: 'flex' }}>
                        <div className="section">
                            <div className="group">
                                <div className="g-content">
                                    <div className="g-title">What’s Included:</div>
                                    <div>
                                        <p className="g-label">Product Description Creation:
                                            <span className="g-value">We’ll craft compelling product descriptions that highlight key features and benefits.</span>
                                        </p>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <p className="g-label">SEO Meta Details:
                                            <span className="g-value">We’ll optimize your product listings with SEO-friendly meta titles and descriptions to improve search engine visibility.</span>
                                        </p>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <p className="g-label">
                                            Catalog Organisation:
                                            <span className="g-value">We’ll ensure that your products are organized and categorized effectively for a seamless shopping experience.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="group">
                                <div className="g-content">
                                    <div className="g-title">What We Need From You:</div>
                                    <div>
                                        <p className="g-label">High-Resolution Product Images:
                                            <span className="g-value">Please provide clear, high-quality images for each product.</span>
                                        </p>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <p className="g-label">Product Information:
                                            <span className="g-value">Share any specific details about your products that we need to include.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section" style={{ background: "#fff", marginLeft: 20 }}>
                            <div className="group">
                                <div className="g-content">
                                    <div>
                                        <p className="g-label">
                                            <span className="g-value" style={{ marginLeft: 0 }}>Use the calculator below to determine the cost of setting up your product catalog based on the number of products you wish to upload.</span>
                                        </p>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <p className="g-label">Pricing:
                                            <span className="g-value">The base cost for up to 50 products is ₹1,000. For every additional product beyond 50, simply adjust the number in the calculator to see your updated pricing.</span>
                                        </p>
                                    </div>
                                    <div style={{ marginTop: 8 }}>
                                        <p className="g-label">Validity:
                                            <span className="g-value" style={{ marginLeft: 0 }}>The service is valid for 60 days from the date of purchase. Within this period, you can request setup for the total number of products you paid for.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="num-products">
                                <div className="np-title">Number of Products <div className="min">(Min 50)</div></div>
                                <div>
                                    <input type="number" min={50} className="np-input" onBlur={() => {
                                        if (isNaN(numberProducts)) {
                                            setNumberProducts(50);
                                        }
                                        if (numberProducts < 50) {
                                            setNumberProducts(50);
                                        }
                                    }} value={numberProducts} defaultValue={50} placeholder="Eg: 100" onChange={(e) => setNumberProducts(parseInt(e.target.value))} />
                                    {!isNaN(numberProducts) && <div>
                                        <div className="output">{20 * numberProducts}</div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action">
                    {error && <div className="error">{error}</div>}
                    <button onClick={onCancel} className="sp_btn cancel">Cancel</button>
                    <button onClick={onProceed} className="sp_btn purchase">Purchase</button>
                </div>
            </div>
        </div>
    </div>
}
export default PurchaseProductCatalog;